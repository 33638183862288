<template>
<div>
    <div class="modal fade" id="reduceModal" tabindex="-1" aria-labelledby="reduceModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-area">
                <div class="modal-header">
                    <h5 class="modal-title modal-title2" id="reduceModalLabel">Close or reduce {{ modalReduceEntry.longshort }} position </h5>

                    <button type="button" class="btn close-butt" data-bs-dismiss="modal" aria-label="Reduce">
                        <div class="btn-tex">
                            x
                        </div>
                    </button>
                </div>

                <div class="modal-body modal-body2">
                    <div class="row top-row-modal">
                        <div class="col">
                            <b>{{ modalReduceEntry.symbol }}</b>
                        </div>
                    </div>

                    <div class="row mid-row-modal modal-row">
                        <div class="col-4">
                            start price
                        </div>
                        <div class="col-4">
                            <!-- start fx -->
                            {{ pricelabel }}
                        </div>
                        <div class="col-4">
                            quantity
                        </div>
                    </div>
                    <div class="row modal-row">
                        <div v-if="Number(modalReduceEntry.startprice)<0.001" class="col-4">
                            {{ (Number(modalReduceEntry.startprice)*100000).toFixed(4) }}E-5
                        </div>
                        <div v-else class="col-4">
                            {{ (Math.round(Number(modalReduceEntry.startprice)*1000)/1000).toLocaleString('en-US') }}
                        </div>
                        <div v-if="Number(this.actionPrice)<0.001" class="col-4">
                            {{ (Number(this.actionPrice)*100000).toFixed(4) }}E-5
                            <!-- {{ modalReduceEntry.startfx }} -->
                        </div>
                        <div v-else class="col-4">
                            {{ (Math.round(Number(this.actionPrice)*1000)/1000).toLocaleString('en-US') }}
                            <!-- {{ modalReduceEntry.startfx }} -->
                        </div>
                        <div class="col-4">
                            {{ Math.round(modalReduceEntry.quantity).toLocaleString('en-US') }}
                        </div>
                    </div>

                    <div class="row mid-row-modal modal-row">
                        <div class="col-4">
                            start fx
                            <!-- {{ pricelabel }} -->
                        </div>
                        <div class="col-4">
                            {{ fxlabel }}
                        </div>
                        <div class="col-4">
                            pl/{{ baseCurrency.toLowerCase() }}
                        </div>
                    </div>
                    <div class="row modal-row">
                        <div class="col-4">
                            {{ modalReduceEntry.startfx }}
                            <!-- {{ this.actionPrice }} -->
                        </div>
                        <div class="col-4">
                            {{ this.actionFx }}
                        </div>
                        <div class="col-4 col-bold">
                            {{ (Math.round(actionUnitPl * modalReduceEntry.reduceQty*100)/100).toLocaleString('en-US') }}
                        </div>
                    </div>

                </div>

                <div class="modal-footer modal-body2 px-0">
                    <div v-if="this.showWarning" class="card warn-card">
                        <div class="container-fluid add-mod-warn">
                            <p> {{ this.warningMsg }} </p>
                            <!-- <div class="row">
                            <div class="col">
                            </div> -->
                            <!-- <div class="col-2">
                                <button  class="btn close-butt" role="button" @click.prevent="this.close()">
                                    <div class="btn-tex">
                                        x
                                    </div>
                                </button>
                            </div> -->
                            <!-- </div> -->
                        </div>
                    </div>
                    <div v-else>
                        <form>
                            <div class="row">

                                <div class="col-5 last-row-modal1">
                                    Quantity to {{ (modalReduceEntry.longshort) == 'long' ? 'sell' : 'buy' }}:
                                </div>
                                <div class="col-4">
                                    <div class="forms-inputs modal-input">
                                        <input type="number" v-model="modalReduceEntry.reduceQty" class="reduce-qty" step=1 min="0" max="1000000000" @drop.prevent="false" @paste.prevent="false" @focus="this.errorMsg=''">
                                    </div>
                                </div>
                                <div class="col-3">
                                    <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                                    <button type="submit" @click.prevent="closePosition(this.modalReduceEntry, this.modalReduceIndex)" class="btn btn-primary modal-btn">{{ (modalReduceEntry.longshort) == 'long' ? 'Sell' : 'Buy' }}</button>
                                </div>

                            </div>
                        </form>
                    </div>
                    <div v-if="this.errorMsg" class="container-fluid d-flex justify-content-center add-mod-warn">
                        {{ this.errorMsg }}
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="modal fade" id="addModal" tabindex="-1" aria-labelledby="addModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content modal-area">
                <div class="modal-header">
                    <h5 class="modal-title modal-title2" id="addModalLabel">Add to existing {{ modalAddEntry.longshort }} position </h5>

                    <button type="button" class="btn close-butt" data-bs-dismiss="modal" aria-label="Add">
                        <!-- <button type="button" class="btn close-butt" aria-label="Close" @click.prevent="hideModal()"> -->
                        <div class="btn-tex">
                            x
                        </div>
                    </button>
                </div>

                <div class="modal-body modal-body2">
                    <div class="row top-row-modal">
                        <div class="col">
                            <b>{{ modalAddEntry.symbol }}</b>
                        </div>
                    </div>

                    <div class="row mid-row-modal modal-row">
                        <div class="col-4">
                            start price
                        </div>
                        <div class="col-4">
                            {{ pricelabel }}
                            <!-- start fx -->
                        </div>
                        <div class="col-4">
                            quantity
                        </div>
                    </div>
                    <div class="row modal-row">
                        <div v-if="Number(modalAddEntry.startprice)<0.001" class="col-4">
                            {{ (Number(modalAddEntry.startprice)*100000).toFixed(4) }}E-5
                        </div>
                        <div v-else class="col-4">
                            {{ (Math.round(Number(modalAddEntry.startprice)*1000)/1000).toLocaleString('en-US') }}
                        </div>
                        <div v-if="Number(this.actionPrice)<0.001" class="col-4">
                            <!-- {{ modalAddEntry.startfx }} -->
                            {{ (Number(this.actionPrice)*100000).toFixed(4) }}E-5
                        </div>
                        <div v-else class="col-4">
                            <!-- {{ modalAddEntry.startfx }} -->
                            {{ (Math.round(Number(this.actionPrice)*1000)/1000).toLocaleString('en-US') }}
                        </div>
                        <div class="col-4">
                            {{ Math.round(modalAddEntry.quantity).toLocaleString('en-US') }}
                        </div>
                    </div>

                    <div class="row mid-row-modal modal-row">
                        <div class="col-4">
                            <!-- {{ pricelabel }} -->
                            start fx
                        </div>
                        <div class="col-4">
                            {{ fxlabel }}
                        </div>
                        <div class="col-4">
                            amount/{{ baseCurrency.toLowerCase()  }}
                        </div>
                    </div>
                    <div class="row modal-row">
                        <div class="col-4">
                            <!-- {{ this.actionPrice }} -->
                            {{ modalAddEntry.startfx }}
                        </div>
                        <div class="col-4">
                            {{ this.actionFx }}
                        </div>
                        <div class="col-4 col-bold">
                            {{ (Math.round(actionPrice * actionFx * modalAddEntry.addQty*100)/100).toLocaleString('en-US') }}
                        </div>
                    </div>

                </div>

                <div class="modal-footer modal-body2 px-0">
                    <div v-if="this.showWarning" class="card warn-card">
                        <div class="container-fluid add-mod-warn">
                            <p> {{ this.warningMsg }} </p>
                        </div>
                    </div>

                    <div v-else>
                        <form>
                            <div class="row">
                                <div class="col-5 last-row-modal1">
                                    Quantity to {{ (modalAddEntry.longshort) == 'long' ? 'buy' : 'sell' }}:
                                </div>
                                <div class="col-4">
                                    <div class="forms-inputs modal-input">
                                        <!-- <BaseInputEnter label="Quantity" v-model="entryPos.quantity" type="number" step=1 /> -->
                                        <input v-model="modalAddEntry.addQty" class="reduce-qty" type="number" step=1 @drop.prevent="false" @paste.prevent="false" @focus="this.errorMsg=''">
                                    </div>
                                </div>
                                <div class="col-3">
                                    <!-- <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> -->
                                    <button type="submit" @click.prevent="addToPosition(this.modalAddEntry, this.modalAddIndex)" class="btn btn-primary modal-btn">{{ (modalAddEntry.longshort) == 'long' ? 'Buy' : 'Sell' }}</button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div v-if="this.errorMsg" class="container-fluid d-flex justify-content-center add-mod-warn">
                        {{ this.errorMsg }}
                    </div>

                </div>

            </div>
        </div>
    </div>

    <!-- {{this.width >= 400}} - {{width}} - {{myId}} - {{particid}} - {{particalias}} - {{particalias.length}} - {{particalias.length<= 14}} - {{titleRow}} -->
    <div v-if="this.titleRow == true" class="row title-row">
        <div class="col d-flex justify-content-center">
            <h2>Portfolio</h2>
        </div>
    </div>

    <div v-if="this.showEmpty" class="card empty-card">
        <div class="row">
            <div class="col-12">
                {{ this.emptyMsgPos }}
            </div>
        </div>
    </div>
    <div v-else-if="this.updatedEntries.length > 0">
        <div class="card card-headers">
            <div class="row">
                <div class="col-3 cell-headers head-1">
                    asset
                </div>
                <div class="col-1 cell-headers head-2">
                    l/s
                </div>
                <div class="col-2 cell-headers head-3">
                    p/l
                </div>
                <div class="col-3 cell-headers head-4">
                    last close
                </div>
                <div class="col-3 cell-headers">
                    value
                </div>
            </div>
        </div>

        <div v-for="entry, index in updatedEntries" :key="entry.id">
            <div class="card entry-card" :id="'poscard-'+index">
                <div class="row">
                    <!-- <div class="col-3 cell-tick cell-left"> -->
                    <div class="col-3 cell-tick">
                        <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{ entry.assetname.split(' ')[0].slice(0, 7) }}</a>
                        <!-- <a class="cell-neu" href="" @click.prevent="toggleSubcard(index)">{{ ('ABCDEFGHILMNO').slice(0, 7) }}</a> -->
                    </div>
                    <div class="col-1 cell-pri cell-mid1">
                        {{ (entry.longshort) == 'long' ? 'L' : 'S' }}
                    </div>
                    <div class="col-2 cell-pri cell-mid2">
                        <!-- <span :class="(entry.currprice / entry.startprice) > 1 ? 'cell-pos' : 'cell-neg'"> -->
                        <!-- {{ (Math.round((entry.currprice / entry.startprice -1) * 10000) / 100).toFixed(2) }} -->
                        <span :class="(entry.amount / entry.startvalue) >= 1 ? 'cell-pos' : 'cell-neg'">
                            {{ entry.amountChg }}
                            <!-- {{ (Math.round((entry.amount / entry.startvalue -1) * 10000) / 100).toFixed(2)}} -->
                            <!-- 1,234.56 -->
                        </span>
                    </div>
                    <div v-if="entry.currprice < 0.001" class="col-3 cell-pri cell-mid3">
                        {{ (entry.currprice * 1000000).toFixed(4) }}E-5
                        <!-- 1,234,567.89 -->
                    </div>
                    <div v-else class="col-3 cell-pri cell-mid3">
                        {{ (Math.round(entry.currprice*100)/100).toLocaleString('en-US') }}
                        <!-- 1,234,567.89 -->
                    </div>
                    <div class="col-3 cell-pri cell-right">
                        {{ Math.round(entry.amount).toLocaleString('en-US') }}
                        <!-- 10,234,567.89 -->
                    </div>
                </div>

                <div class="row">
                    <div class="col-3 cell-qty">
                        {{ entry.quantity.toLocaleString('en-US') }}
                        <!-- 10,234,567 -->
                    </div>
                    <div class="col-1 cell-curr">

                    </div>
                    <div class="col-2 cell-curr">
                        %
                    </div>
                    <div class="col-3 cell-curr">
                        {{ entry.currency.toLowerCase() }}
                    </div>
                    <div class="col-3 cell-curr">
                        {{ baseCurrency.toLowerCase() }}
                    </div>
                </div>

            </div>

            <div v-show="showCard[index]" class="card entry-subcard" :id="'possubcard-'+index">
                <div class="row">
                    <div class="col-1 cell-subc cell-subc-left">

                    </div>
                    <div class="col-3 cell-subc subc-head cell-subc-mid1">
                        start
                    </div>
                    <div class="col-2 cell-subc subc-head cell-subc-mid2">
                        chg%
                    </div>
                    <div class="col-3 cell-subc subc-head cell-subc-mid3">
                        last close
                    </div>
                    <div class="col-3 cell-subc subc-head cell-subc-right">
                        p/l
                    </div>
                </div>

                <div class="row">
                    <div class="col-1 cell-subc cell-titl cell-subc-left">
                        pri
                    </div>
                    <div v-if="entry.startprice < 0.001" class="col-3 cell-subc cell-subc-mid1">
                        {{ (entry.startprice*1000000).toFixed(4) }}E-5
                        <!-- 1,234,567.890 -->
                    </div>
                    <div v-else class="col-3 cell-subc cell-subc-mid1">
                        {{ (Math.round(entry.startprice*100)/100).toLocaleString('en-US') }}
                        <!-- 1,234,567.890 -->
                    </div>
                    <div class="col-2 cell-subc cell-subc-mid2">
                        <span :class="(entry.currprice / entry.startprice) >= 1 ? 'cell-pos' : 'cell-neg2'">
                            {{ entry.priChg }}
                            <!-- {{ (Math.round((entry.currprice / entry.startprice -1) * 10000) / 100).toFixed(2)}} -->
                            <!-- 1,234.56 -->
                        </span>
                    </div>
                    <div v-if="entry.currprice < 0.001" class="col-3 cell-subc cell-subc-mid3">
                        {{ (entry.currprice * 1000000).toFixed(4) }}E-5
                        <!-- 1,234,567.89 -->
                    </div>
                    <div v-else class="col-3 cell-subc cell-subc-mid3">
                        {{ (Math.round(entry.currprice*100)/100).toLocaleString('en-US') }}
                        <!-- 1,234,567.89 -->
                    </div>
                    <div class="col-3 cell-subc cell-subc-right">
                        {{ (Math.round(entry.prieffect *100)/100).toLocaleString('en-US') }}
                        <!-- 10,234,567 -->
                    </div>
                </div>

                <div class="row">
                    <div class="col-1 cell-subc cell-titl cell-subc-left">
                        fx
                    </div>
                    <div class="col-3 cell-subc cell-subc-mid1">
                        {{ (Math.round(entry.startfx * 10000) / 10000).toFixed(4) }}
                        <!-- 10.0001 -->

                    </div>
                    <div class="col-2 cell-subc cell-subc-mid2">
                        <span :class="(entry.currfx / entry.startfx) >= 1 ? 'cell-pos' : 'cell-neg2'">
                            <!-- {{ (Math.round((entry.currfx / entry.startfx -1) * 10000) / 100).toFixed(2)}} -->
                            {{ entry.fxChg }}
                            <!-- 1,234.56 -->
                        </span>

                    </div>
                    <div class="col-3 cell-subc cell-subc-mid3">
                        {{ (Math.round(entry.currfx * 10000) / 10000).toFixed(4) }}
                        <!-- 10.0001 -->

                    </div>
                    <!-- <div class="col-3 cell-subc cell-subc-right" v-show="entry.longshort=='long'"> -->
                    <div class="col-3 cell-subc cell-subc-right">
                        {{ (Math.round(entry.fxeffect *100)/100).toLocaleString('en-US') }}
                        <!-- 10,234,567.89 -->
                    </div>
                </div>

                <div class="row">
                    <div class="col-1 cell-subc cell-titl cell-subc-left">
                        {{ baseCurrency.toLowerCase() }}
                    </div>
                    <div class="col-3 cell-subc cell-subc-mid1">
                        {{ Math.round(entry.startvalue).toLocaleString('en-US') }}
                        <!-- 10,234,567.89 -->
                    </div>
                    <div class="col-2 cell-subc cell-subc-mid2">
                        <span :class="(entry.amount / entry.startvalue) >= 1 ? 'cell-pos' : 'cell-neg2'">
                            <!-- {{ (Math.round((entry.amount / entry.startvalue -1) * 10000) / 100).toFixed(2)}} -->
                            {{ entry.amountChg }}
                            <!-- 1,234.56 -->
                        </span>
                    </div>
                    <div class="col-3 cell-subc cell-subc-mid3">
                        {{ Math.round(entry.amount).toLocaleString('en-US') }}
                        <!-- 10,234,567.89 -->
                    </div>
                    <div class="col-3 cell-subc cell-subc-right">
                        {{ (Math.round(entry.pl*100) / 100).toLocaleString('en-US') }}
                        <!-- 1,234,567.89 -->
                    </div>
                </div>

                <div class="row">
                    <div class="col-4 cell-subc comments-cell cell-subc-low1">
                        <a v-if="this.commentsVisible" class="cell-light" href="" @click.prevent="toggleComments(entry, index)">comments</a>
                        <span v-else class="cell-disabled">comments</span>
                    </div>
                    <div class="col-4 cell-subc closepos-cell cell-subc-low2 px-0" v-if="this.showCloseLink">
                        <a class="cell-light" href="" @click.prevent="showReduceModal(entry, index)">reduce/close</a>
                    </div>
                    <div class="col-2 cell-subc closepos-cell cell-subc-low3 px-0" v-if="this.showCloseLink">
                        <a class="cell-light" href="" @click.prevent="showAddModal(entry, index)">add</a>
                    </div>
                    <div class="col-2 cell-subc closepos-cell cell-subc-low3 px-0" v-if="this.showCloseLink">
                        <a class="cell-light" href="" @click.prevent="toggleAlert(entry, index)">alert</a>
                    </div>
                </div>
            </div>

            <div v-show="showComment[index]" class="container-fluid px-0">
                <div class="card comments-card">
                    <div class="row">
                        <div class="col line-break-and-tab" :id="'poscomment-'+index">
                            <p> {{ this.updatedcomment[index] }} </p>
                        </div>
                    </div>
                </div>

                <div v-show="!this.textArea[index] && this.commentsAllowed" class="row">
                    <div class="col">
                        <button role="button" @click.prevent="this.addComment(index)" class="btn addcomm-btn btn3d btn-primar">Add a comment</button>
                    </div>
                </div>
                <div v-show="this.textArea[index]" class="row">
                    <div class="col-sm-12">
                        <div class="form-floating">
                            <textarea class="form-control comment-textarea" maxlength="3000" :id="'posfloatingTextarea-' + index" v-model="this.newcomment[index]" @drop.prevent="false" @paste.prevent="false"></textarea>
                        </div>
                    </div>
                </div>
                <div v-show="this.textArea[index]" class="row">
                    <!-- <button v-show="this.textArea[index]" role="button" @click.prevent="enterComment(entry, index)" class="btn shadow rounded entercomm-btn">Enter comment</button> -->
                    <!-- <button v-show="this.textArea[index]" role="button" @click.prevent="this.newcomment[index] = ''; toggleComments(entry, index);" class="btn shadow rounded entercomm-btn">Cancel</button> -->
                    <div class="col-10">
                        <button role="button" @click.prevent="enterComment(entry, index)" class="btn entercomm-btn btn3d btn-primar">Enter comment</button>
                        <!-- </div>
                    <div class="col-3"> -->
                        <button role="button" @click.prevent="this.newcomment[index] = ''; this.textArea[index] = false;" class="btn btn-secondary btn-secondary-comment shadow rounded ">Cancel</button>
                    </div>

                </div>
            </div>

            <div v-show="showAlert[index]" class="container-fluid px-0">
                <div class="card comments-card pt-2">
                    <div class="row" :id="'posalert-'+index">
                        <div class="col">
                            <p>Get notification when price closes:</p>
                        </div>
                    </div>
                    <div v-if="modifyAbove[index]" class="row alert-row-1-open g-0">
                        <div class="col-2">
                            Above:
                        </div>
                        <div class="col-3 col-prialert col-alertbox">
                            <!-- <BaseInputEnter v-model="alertAbove[index]" type="number" step=0.0001 @drop.prevent="false" @paste.prevent="false" @blur="validAlert($event.target.value)" class="alert-box" :id="'abovebox-'+index"/> -->
                            <input v-model="alertAbove[index]" type="number" step=0.0001 @drop.prevent="false" @paste.prevent="false" class="alert-box" :id="'posabovebox-'+index" />
                        </div>
                        <div class="col-2">
                            <b>{{entry.currency.toLowerCase()}}</b>
                        </div>
                        <div class="col-2 col-alert-enter">
                            <button role="button" @click.prevent="enterAlert(entry, index, 'above', true)" class="btn btn-primar btn-alert-enter btn3d">Enter</button>
                        </div>
                        <div class="col-2 col-alert-cancel">
                            <button role="button" @click.prevent="cancelAlert(entry, index, 'above')" class="btn btn-primar btn-alert-cancel">Cancel</button>
                        </div>
                    </div>

                    <div v-else class="row alert-row-1 g-0">
                        <div class="col-2">
                            Above:
                        </div>
                        <div class="col-3 col-prialert">
                            <b>{{alertAbove[index]}}</b>
                        </div>
                        <div class="col-2">
                            <b>{{entry.currency.toLowerCase()}}</b>
                        </div>
                        <div v-if="entry.alertabove!=0" class="col-2 col-alert-enter">
                            <button role="button" @click.prevent="modifyAlert(index, 'above')" class="btn btn-primar btn-alert-modify btn3d">Modify</button>
                        </div>
                        <div v-if="entry.alertabove!=0" class="col-2 col-alert-cancel">
                            <button role="button" @click.prevent="enterAlert(entry, index, 'above', false)" class="btn btn-primar btn-alert-remove">Remove</button>
                        </div>
                        <div v-if="entry.alertabove==0" class="col-4 col-alert-set">
                            <button role="button" @click.prevent="modifyAlert(index, 'above')" class="btn btn-primar btn-alert-set btn3d">Create alert</button>
                        </div>
                    </div>

                    <div v-if="modifyBelow[index]" class="row alert-row-2-open g-0">
                        <div class="col-2">
                            Below:
                        </div>
                        <div class="col-3 col-prialert col-alertbox">
                            <!-- <BaseInputEnter v-model="alertBelow[index]" type="number" step=0.0001 @drop.prevent="false" @paste.prevent="false" @blur="validAlert($event.target.value)" class="alert-box" :id="'belowbox-'+index"/> -->
                            <input v-model="alertBelow[index]" type="number" step=0.0001 @drop.prevent="false" @paste.prevent="false" class="alert-box" :id="'posbelowbox-'+index" />
                        </div>
                        <div class="col-2">
                            <b>{{entry.currency.toLowerCase()}}</b>
                        </div>
                        <div class="col-2 col-alert-enter">
                            <button role="button" @click.prevent="enterAlert(entry, index, 'below', true)" class="btn btn-primar btn-alert-enter btn3d">Enter</button>
                        </div>
                        <div class="col-2 col-alert-cancel">
                            <button role="button" @click.prevent="cancelAlert(entry, index, 'below')" class="btn btn-primar btn-alert-cancel">Cancel</button>
                        </div>
                    </div>
                    <div v-else class="row alert-row-2 g-0">
                        <div class="col-2">
                            Below:
                        </div>
                        <div class="col-3 col-prialert">
                            <b>{{alertBelow[index]}}</b>
                        </div>
                        <div class="col-2">
                            <b>{{entry.currency.toLowerCase()}}</b>
                        </div>
                        <div v-if="entry.alertbelow!=0" class="col-2 col-alert-enter">
                            <button role="button" @click.prevent="modifyAlert(index, 'below')" class="btn btn-primar btn-alert-modify btn3d">Modify</button>
                        </div>
                        <div v-if="entry.alertbelow!=0" class="col-2 col-alert-cancel">
                            <button role="button" @click.prevent="enterAlert(entry, index, 'below', false)" class="btn btn-primar btn-alert-remove">Remove</button>
                        </div>
                        <div v-if="entry.alertbelow==0" class="col-4 col-alert-set">
                            <button role="button" @click.prevent="modifyAlert(index, 'below')" class="btn btn-primar btn-alert-set btn3d">Create alert</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <!-- The closing div above is closing the if-else and allowing to see the summary -->

    <div v-if="this.showEmpty">
        <div class="row spacer-row"></div>
    </div>

    <!-- OLD SUMMARY WITH VERTICAL SPLIT -->
    <!-- <div class="card summ-card pt-1 pb-0">
        <div class="row row-summ">
            <div class="col-3 cell-titles cell-low1 cell-low11 cell-border-tl py-1 cell-border-tr">
                Cash
            </div>
            <div class="col-1 cell-titles cell-summ-empty cell-border-tl py-1">
            </div>
            <div class="col-2 cell-titles cell-low2 cell-pl  py-1">
                p/l
            </div>
            <div class="col-3 cell-titles cell-low3 cell-pl py-1">
                p/l
            </div>
            <div class="col-3 cell-titles cell-low4 cell-low11 cell-border-tr py-1">
                Portfolio
            </div>
        </div>
        <div class="row row-summ">
            <div class="col-3 cell-pri-summ cell-low1 px-0">
                {{ Math.round(this.usercash).toLocaleString('en-US')  }}
            </div>
            <div class="col-1 cell-pri-summ cell-summ-empty">
            </div>
            <div class="col-2 cell-pri-summ cell-low2">
                <span :class="(this.portfolioCurrValue/this.portfolioStartValue) >= 1 ? 'cell-pos' : 'cell-neg'">
                    {{ this.portfolioChg }}
                </span>
            </div>
            <div class="col-3 cell-pri-summ cell-low3">
                {{ Math.round((this.portfolioCurrValue - this.portfolioStartValue)).toLocaleString('en-US')  }}
            </div>
            <div class="col-3 cell-pri-summ cell-low4">
                {{ (Math.round(this.portfolioCurrValue)).toLocaleString('en-US')}}
            </div>
        </div>
        <div class="row mb-0 pb-0 row-summ bottom-row-summ">
            <div class="col-3 cell-titles cell-low1 cell-curr-summ cell-border-bl cell-border-br">
                {{ baseCurrency.toLowerCase()  }}
            </div>
            <div class="col-1 cell-titles cell-summ-empty cell-border-bl">
            </div>
            <div class="col-2 cell-titles cell-low2 cell-curr-summ ">
                %
            </div>
            <div class="col-3 cell-titles cell-low3 cell-curr-summ">
                {{ baseCurrency.toLowerCase()  }}
            </div>
            <div class="col-3 cell-titles cell-low4 cell-curr-summ cell-border-br">
                {{ baseCurrency.toLowerCase()  }}
            </div>
        </div>
    </div> -->
    <!-- END OLD SUMMARY -->

    <div class="card total-card" id="summary-card">
        <div class="row summ-toprow">
            <div class="col-3 summ-title">
                <a class="cell-neu" href="" @click.prevent="toggleSummary()">SUMMARY</a>
                <!-- SUMMARY -->
            </div>
            <div class="col-1 summ-font cell-pos12">
            </div>
            <div class="col-2 summ-font summ-pos13">
                p/l
            </div>
            <div class="col-3 summ-font summ-pos14">
                p/l
            </div>
            <div class="col-3 summ-font summ-pos15">
                value
            </div>
        </div>
        <div class="divider sep-line "></div>
        <div class="row mt-1">
            <div class="col-3 summ-font summ-pos11">
                portfolio
            </div>
            <div class="col-1 summ-font cell-pos12">
            </div>
            <div class="col-2 summ-num summ-pos13">
                <span :class="(this.portfolioCurrValue/this.portfolioStartValue) >= 1 ? 'cell-pos' : 'cell-neg'">
                    {{ this.portfolioChg }}
                </span>
            </div>
            <div class="col-3 summ-num summ-pos14">
                {{ Math.round((this.portfolioCurrValue - this.portfolioStartValue)).toLocaleString('en-US')  }}
            </div>
            <div class="col-3 summ-num summ-pos15">
                {{ (Math.round(this.portfolioCurrValue)).toLocaleString('en-US')}}
            </div>
        </div>
        <div class="row mb-1">
            <div class="col-3 summ-font summ-pos11">
                realized/cash
            </div>
            <div class="col-1 summ-font cell-pos12">
            </div>
            <div class="col-2 summ-num summ-pos13">
                -
                <!-- 10,234.00 -->
            </div>
            <div class="col-3 summ-num summ-pos14">
                {{ Math.round(Number(this.accruedpl) + Number(this.dividends) - Number(this.fees)).toLocaleString('en-US')}}
                <!-- 100,000,000 -->
            </div>
            <div class="col-3 summ-num summ-pos15">
                {{ Math.round(this.usercash).toLocaleString('en-US')}}
                <!-- 100,000,000 -->
            </div>
        </div>
        <div class="divider sep-line "></div>
        <div class="row mt-1 total-row">
            <div class="col-3 summ-font summ-pos11">
                total
            </div>
            <div class="col-1 summ-font cell-pos12">
            </div>
            <div class="col-2 summ-num summ-pos13">
                <span :class="((this.portfolioCurrValue + this.usercash)/this.startcash) >= 1 ? 'cell-pos' : 'cell-neg'">
                    {{ this.capitalChg }}
                </span>
            </div>
            <div class="col-3 summ-num summ-pos14">
                {{ Math.round((this.portfolioCurrValue + this.usercash - this.startcash)).toLocaleString('en-US')  }}
            </div>
            <div class="col-3 summ-num summ-pos15">
                {{ Math.round(this.portfolioCurrValue + this.usercash).toLocaleString('en-US')}}
            </div>
        </div>

        <div class="row">
            <div class="col-3 summ-font summ-pos11">
            </div>
            <div class="col-1 summ-font cell-pos12">
            </div>
            <div class="col-2 summ-num summ-pos13">
                %
            </div>
            <div class="col-3 summ-num summ-pos14">
                {{ baseCurrency.toLowerCase() }}
            </div>
            <div class="col-3 summ-num summ-pos15">
                {{ baseCurrency.toLowerCase() }}
            </div>
        </div>
    </div>

    <div v-show="showSummCard" class="card summ-subcard">
        <div class="row mb-1">
            <div class="col-3 summ-font2 summ-pos21">
                <!-- initial cash ({{ baseCurrency.toLowerCase() }}) -->
                initial cash
            </div>
            <div class="col-6 summ-num summ-pos22">
            </div>
            <div class="col-3 summ-num summ-pos26">
                {{ Math.round((this.startcash)).toLocaleString('en-US') }}
                <!-- 100,234,567 -->
            </div>
        </div>
        <div class="divider sep-line2"></div>
        <div class="row mt-2">
            <div class="col-3 summ-font2 summ-pos21">
                realized p/l
            </div>
            <div class="col-6 summ-num summ-pos22">
            </div>
            <div class="col-3 summ-num summ-pos26">
                {{ Math.round((this.accruedpl)).toLocaleString('en-US') }}
                <!-- 100,234,567 -->
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-3 summ-font2 summ-pos21">
                dividends
            </div>
            <div class="col-6 summ-num summ-pos22">
            </div>
            <div class="col-3 summ-num summ-pos26">
                {{ Math.round((this.dividends)).toLocaleString('en-US') }}
                <!-- 34,567 -->
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-3 summ-font2 summ-pos21">
                buying fees
            </div>
            <div class="col-6 summ-num summ-pos22">
            </div>
            <div class="col-3 summ-num summ-pos26">
                <span v-show="Number(this.fees)>0">-</span>{{ Math.round((this.fees)).toLocaleString('en-US') }}
                <!-- -234,567 -->
            </div>
        </div>
        <div class="row mt-2 mb-1">
            <div class="col-3 summ-font2 summ-pos21">
                assets cost
            </div>
            <div class="col-6 summ-num summ-pos22">
            </div>
            <div class="col-3 summ-num summ-pos26">
                <span v-show="this.portfolioStartValue>0">-</span>{{ Math.round((this.portfolioStartValue)).toLocaleString('en-US') }}
                <!-- -234,567 -->
            </div>
        </div>
        <div class="divider sep-line2"></div>
        <div class="row mt-2">
            <!-- <div class="col-9 summ-font cell-titl cell-subc-left"> -->
            <div class="col-3 summ-font2 summ-pos21">
                cash
            </div>
            <div class="col-6 summ-num summ-pos22">
            </div>
            <div class="col-3 summ-num summ-pos26">
                {{ Math.round(this.usercash).toLocaleString('en-US')}}
                <!-- 100,000,000 -->
            </div>
        </div>
        <!-- <div class="row mt-2">
            <div class="col-9 cell-subc cell-titl cell-subc-left">

            </div>
            <div class="col-3 cell-subc cell-subc-right">
                {{ baseCurrency.toLowerCase() }}
            </div>
        </div> -->
    </div>

</div>
</template>

<script>
import apiCalls from '@/services/apiCalls.js'
import yfapiCalls from '@/services/yfapiCalls.js'
import cgapiCalls from '@/services/cgapiCalls.js'
import store from '@/store'
import {
    Modal
} from 'bootstrap'
import {
    mapActions
} from 'vuex'
// import router from '@/router'
export default {
    name: 'Itemdata1Table',
    data() {
        return {
            width: 0,
            showEmpty: false,
            emptyMsgPos: '',
            particId: null,
            // particIcon: null,
            showCloseLink: false,
            entries: [],
            updatedEntries: [],
            currencies: [],
            showCard: [],
            showComment: [],
            textArea: [],
            // showClose: [],
            newcomment: [],
            // initialcomment: [],
            updatedcomment: [],

            openpos: 0, // These 4 are not needed in Watchlist
            portfolioCurrValue: 0,
            // portfolioStartValue: Number.EPSILON,
            portfolioStartValue: 0,
            portfolioChg: 0,
            usercash: 0,
            startcash: 0,
            capitalChg: 0,
            userportfolio: null,
            // takenassets: '',
            // takenassetsObj: {},
            modalReduceEntry: {},
            modalReduceIndex: null,
            modalAddEntry: {},
            modalAddIndex: null,
            currAssetData: [],

            showWarning: false,
            warningMsg: '',
            errorMsg: '',
            actionPrice: null,
            // counterPrice: null,
            actionFx: null,
            usdAskRate: null,
            actionUnitPl: null,
            actionUnitPlUsd: null,
            // marketopen: false,
            pricelabel: 'price',
            fxlabel: 'fx',
            baseCurrency: null,
            hideComments: false,
            stopComments: false,
            commentsVisible: false,
            commentsAllowed: false,
            showSummCard: false,
            accruedpl: 0,
            fees: 0,
            dividends: 0,

            showAlert: [],
            modifyAbove: [],
            modifyBelow: [],
            alertAbove: [],
            alertBelow: [],
        }
    },
    props: [
        'category',
    ],
    computed: {
        titleRow() {
            if (store.state.page == '/portfolio' && store.state.particId && store.state.particId != store.state.myPid) {
                if (Number(this.width) >= 500) {
                    return false
                } else if (this.width >= 400 && store.state.particAlias.length <= 14) {
                    return false
                } else if (this.width >= 400) {
                    return true
                } else if (this.width >= 360 && store.state.particAlias.length <= 12) {
                    return false
                } else {
                    return true
                }
            } else {
                return false
            }
        },
        // baseCurrency() {
        //     return store.state.myCur
        // },
    },
    methods: {
        ...mapActions(['setRfrsh', 'setAlertMsg', 'setParticIcon']),
        async getAssets() {
            try {
                this.emptyMsgPos = 'Portfolio currently empty'
                // let endPoint = '/positions/'
                // let params = {}
                // if (this.particId && this.particId != store.state.myPid) { // Looking at different participant than myself
                //     // console.log('this.particId: ', this.particId)
                //     params = {
                //         'participant': this.particId,
                //         'closed': 0
                //     }
                //     this.emptyMsgPos = 'Portfolio currently empty'
                // } else {
                //     params = {
                //         'participant': store.state.myPid,
                //         'closed': 0
                //     }
                //     this.emptyMsgPos = 'Portfolio currently empty'
                // }
                const Entries = await apiCalls.getEntries('/positions/', {
                    'participant': this.particId,
                    'closed': 0
                })

                if (Entries && Entries.data.length > 0) {
                    this.entries = Entries.data
                    this.entries.forEach(asset => { // RECENT 231222
                        asset.quantity = Number(asset.quantity)
                    })
                    // console.log('this.entries: ', this.entries)
                    // this.updatedcomment = Entries.data.map(a => a.comment)
                    // console.log('this.updatedcomment: ', this.updatedcomment)
                    // this.initialcomment = Entries.data.map(a => a.comment)
                    // console.log('this.updatedcomment: ', this.initialcomment)     

                    await this.getAssetsData()

                } else {
                    return this.showEmpty = true
                }
            } catch (error) {
                if (store.state.alertMessage == 1) {
                    return alert(error)
                } else {
                    return console.log('errorTI11: ', error)
                }
            }
        },
        async getAssetsData() { // These are the stored data, updated every night after markets close
            this.portfolioCurrValue = 0
            this.portfolioStartValue = 0
            this.portfolioChg = 0
            try {
                this.updatedEntries = this.entries
                // console.log('this.updatedEntries1: ', this.updatedEntries)
                this.currencies = []
                this.alertAbove = []
                this.alertBelow = []
                this.updatedEntries.forEach(asset => {
                    asset.currprice = Number(asset.lastprice)
                    // console.log('asset.lastprice: ', asset.lastprice)
                    // console.log('asset.currprice: ', asset.currprice)
                    // asset.startvalue2 = asset.quantity * Number(asset.startprice) * Number(asset.startfx)
                    // console.log('startvalue -startvalue2: ', asset.startvalue + '-' + asset.startvalue2)

                    // console.log('asset: ', asset)
                })
                // console.log('Here')
                const Currencies = await apiCalls.getEntries('/currencies/', {})
                this.currencies = Currencies.data

                console.log('currencies: ', Currencies)

                let fxRates = {}
                this.currencies.forEach(currObj => {
                    fxRates[currObj.symbol] = Number(currObj.lastprice)
                })
                this.updatedEntries.forEach(uE => {
                    if (uE.currencysymbol) { // The asset is priced in a currency different than my base currency
                        // console.log(uE.symbol)
                        // console.log('currency: ', uE.currencysymbol)
                        // console.log('it is in another currency')
                        // if (uE.longshort == 'long') {
                        uE.currvalue = uE.quantity * Number(uE.lastprice) * fxRates[uE.currencysymbol] // NOTICE: currvalue is the value of the asset at current market price. HOWEVER, amount (see below) is the value of the position if closed. For a long it is the same as currvalue, but in the case of a short is the startvalue + pl
                        console.log('uE.quantity: ', uE.quantity)
                        console.log('Number(uE.lastprice): ', Number(uE.lastprice))
                        console.log('fxRates[uE.currencysymbol]: ', fxRates[uE.currencysymbol])
                        console.log('uE.currencysymbol: ', uE.currencysymbol)
                        // } else if (uE.longshort == 'short') {

                        // }

                        uE.currfx = fxRates[uE.currencysymbol]
                    } else if (!uE.currencysymbol) { // The asset is priced in my base currency
                        // console.log(uE.symbol)
                        // console.log('currency: ', uE.currencysymbol)
                        // console.log('it is in my base currency')
                        uE.currvalue = uE.quantity * Number(uE.lastprice)
                        uE.currfx = 1.0000
                    }
                    switch (uE.longshort) {
                        case 'long':
                            console.log('long position: ', uE.symbol)
                            uE.pl = uE.currvalue - Number(uE.startvalue)
                            console.log('QQQQQQQQ ue.pl: ', uE.pl)
                            console.log('QQQQQQQQ uE.currvalue: ', uE.currvalue)
                            console.log('QQQQQQQQ Number(uE.startvalue): ', Number(uE.startvalue))
                            uE.amount = uE.currvalue // amount is the value if the position is closed at last price and last fx (for longs is the same as currvalue)
                            console.log('QQQQQQQQ uE.amount: ', uE.amount)
                            uE.fxeffect = uE.amount * (1 - Number(uE.startfx) / uE.currfx)
                            console.log('QQQQQQQQ uE.fxeffect: ', uE.fxeffect)
                            uE.prieffect = uE.pl - uE.fxeffect
                            break
                        case 'short':
                            console.log('short position: ', uE.symbol)
                            console.log('uE.startvalue: ', Number(uE.startvalue))
                            console.log('uE.currvalue: ', uE.currvalue)
                            uE.pl = (Number(uE.startprice) - Number(uE.lastprice)) * uE.currfx * uE.quantity
                            // uE.pl = Number(uE.startvalue) - uE.currvalue
                            console.log('uE.pl: ', uE.pl)
                            uE.amount = Number(uE.startvalue) + uE.pl // amount is the value if the position is closed at last price and last fx (for shorts is different than currvalue!)
                            console.log('uE.amount: ', uE.amount)
                            // uE.fxeffect = ((Number(uE.startprice) - Number(uE.lastprice)) * uE.currfx / Number(uE.startfx))
                            uE.fxeffect = uE.pl * (1 - Number(uE.startfx) / uE.currfx)
                            uE.prieffect = uE.pl - uE.fxeffect
                            break
                    }

                    uE.priChg = new Intl.NumberFormat("en-US", {
                        signDisplay: "exceptZero"
                    }).format(Math.round((uE.currprice / uE.startprice - 1) * 10000) / 100)
                    uE.fxChg = new Intl.NumberFormat("en-US", {
                        signDisplay: "exceptZero"
                    }).format(Math.round((uE.currfx / uE.startfx - 1) * 10000) / 100)
                    uE.amountChg = new Intl.NumberFormat("en-US", {
                        signDisplay: "exceptZero"
                    }).format(Math.round((uE.amount / uE.startvalue - 1) * 10000) / 100)

                    this.portfolioStartValue = this.portfolioStartValue + Number(uE.startvalue)
                    this.portfolioCurrValue = this.portfolioCurrValue + uE.amount // NOTICE: the portfolio evaluation includes the amount not the currvalue!
                    console.log('HH portfolioStartValue: ', this.portfolioStartValue)
                    console.log('HH uE.startvalue: ', Number(uE.startvalue))
                    console.log('HH2 portfolioStartValue: ', this.portfolioStartValue)

                })
                this.portfolioChg = new Intl.NumberFormat("en-US", {
                    signDisplay: "exceptZero"
                }).format(Math.round((this.portfolioCurrValue / this.portfolioStartValue - 1) * 10000) / 100)

                this.capitalChg = new Intl.NumberFormat("en-US", {
                    signDisplay: "exceptZero"
                }).format(Math.round(((this.portfolioCurrValue + this.usercash) / this.startcash - 1) * 10000) / 100)
                let roundAmount = this.startcash + Number(this.accruedpl) + Number(this.dividends) - Number(this.fees) - this.portfolioStartValue - this.usercash
                // console.log(typeof this.startcash, ' ',typeof this.accruedpl, ' ',typeof this.dividends, ' ',typeof this.fees, ' ',typeof this.portfolioStartValue, ' ',typeof this.usercash)
                console.log('rounding amount: ', roundAmount)
                // if (Math.abs(roundAmount) > 5) {
                //     await this.setAlertMsg('Rounding seems too high, there might be an issue with some value or with some calculation. The administrators have been informed of the issue.')
                // }

            } catch (error) {
                if (store.state.alertMessage == 1) {
                    return alert(error)
                } else {
                    return console.log('errorTI12: ', error)
                }
            }
        },
        // async getAssetsDataFromApi() {  // NOT USED TO SAVE API CALLS - VALUES ARE TAKEN FROM LASTPRICE, I.E. THE LAST CLOSING PRICES ENTERED ONCE PER DAY AFTER CLOSING IN THE DB FOR EACH POSITION
        //     try {
        //         let symbols = ''
        //         this.entries.forEach(obj => {
        //             obj.symbol = obj.assetname.split(' ')[0]
        //             symbols = symbols + obj.symbol + ','
        //         })
        //         symbols = symbols.slice(0, -1) // eliminates the final comma
        //         let params = {
        //             // lang: 'en',
        //             symbols: symbols,
        //         }
        //         let assetsData = []
        //         if (store.state.apiUrl == 'yf') {
        //             assetsData = await yfapiCalls.getYF(params)
        //         } else {
        //             assetsData = await yfapiCalls.getRapid(params)
        //         }
        //         // console.log('assetsData: ', assetsData.data.quoteResponse.result)
        //         this.updatedEntries = []
        //         this.currencies = []
        //         this.entries.forEach(asset => {
        //             asset = {
        //                 ...asset,
        //                 ...assetsData.data.quoteResponse.result.find(e => e.symbol == asset.symbol)
        //             }
        //             switch (asset.longshort) {
        //                 case 'long':
        //                     asset.currprice = asset.bid
        //                     break
        //                 case 'short':
        //                     asset.currprice = asset.ask
        //                     break
        //             }
        //             if (asset.ask == 0 || asset.bid == 0) {
        //                 asset.currprice = asset.regularMarketPrice
        //             }
        //             this.updatedEntries.push(asset)
        //             if (!this.currencies.includes(asset.currency)) {
        //                 this.currencies.push(asset.currency)
        //             }
        //         })
        //         params['symbols'] = ''
        //         this.currencies.forEach(item => {
        //             params['symbols'] = params['symbols'] + item + store.state.myCur + '=X' + ','
        //         })
        //         params['symbols'] = params['symbols'].slice(0, -1) // eliminates the final comma
        //         let fxData = []
        //         if (store.state.apiUrl == 'yf') {
        //             fxData = await yfapiCalls.getYF(params)
        //         } else {
        //             fxData = await yfapiCalls.getRapid(params)
        //         }
        //         // this.entry.startfx = Math.round((fx + Number.EPSILON) * 10000) / 10000
        //         let fxRates = {}
        //         fxData.data.quoteResponse.result.forEach(currObj => {
        //             fxRates[currObj.symbol.slice(0, 3)] = (currObj.ask + currObj.bid) / 2
        //         })
        //         this.updatedEntries.forEach(uE => {
        //             uE.currvalue = uE.quantity * uE.currprice * fxRates[uE.currency]
        //             uE.currfx = fxRates[uE.currency]
        //             switch (uE.longshort) {
        //                 case 'long':
        //                     uE.amount = uE.currvalue
        //                     uE.pl = uE.currvalue - Number(uE.startvalue)
        //                     uE.fxeffect = uE.amount - (uE.amount * Number(uE.startfx) / uE.currfx)
        //                     break
        //                 case 'short':
        //                     uE.pl = uE.startvalue - uE.currvalue
        //                     uE.amount = uE.startvalue + uE.pl
        //                     uE.fxeffect = uE.amount - (uE.amount * uE.currfx / Number(uE.startfx))
        //                     break
        //             }
        //             this.portfolioCurrValue = this.portfolioCurrValue + uE.amount
        //         })
        //     } catch (error) {
        //         if (store.state.alertMessage == 1) {
        //             return alert('errTI12: ', error)
        //         } else {
        //             return console.log('errorTI12: ', error)
        //         }
        //     }
        // },
        async getCurrentPriceFromApi(entry, _action) { // This is needed for individual assets if I want to close, reduce or add to the position

            try {

                if (entry.assetname.indexOf(' - CRYPTO - ') != -1) {
                    return await this.getCryptoPriceFromApi(entry, _action)
                }

                let symbols = ''
                let askedSymbols = 1
                this.actionFx = 1.0000
                this.usdAskRate = 1.0000
                // symbols = entry.symbol + ',' + entry.currencysymbol + ',' + 'USD' + store.state.myCur + '=X'
                if (entry.currency == store.state.myCur && store.state.myCur == 'USD') {
                    symbols = entry.symbol
                } else if ((entry.currency == store.state.myCur && store.state.myCur != 'USD') || (entry.currency != store.state.myCur && store.state.myCur != 'USD' && entry.currency == 'USD')) {
                    // symbols = entry.symbol + ',' + 'USD' + store.state.myCur + '=X'
                    symbols = entry.symbol + ',' + store.state.myCur + '=X' // since the actual symbol for USDZZZ=X is ZZZ=X
                    askedSymbols = 2
                } else if (entry.currency != store.state.myCur && store.state.myCur != 'USD' && entry.currency != 'USD') {
                    // symbols = entry.symbol + ',' + entry.currencysymbol + ',' + 'USD' + store.state.myCur + '=X'
                    symbols = entry.symbol + ',' + entry.currencysymbol + ',' + store.state.myCur + '=X' // since the actual symbol for USDZZZ=X is ZZZ=X
                    askedSymbols = 3
                } else if (entry.currency != store.state.myCur && store.state.myCur == 'USD') {
                    symbols = entry.symbol + ',' + entry.currencysymbol
                    askedSymbols = 2
                }
                let params = {
                    // lang: 'en',
                    symbols: symbols,
                }
                console.log('params: ', params)
                let tickerData = [] // It's a list because it includes the asset plus its currency
                if (store.state.apiUrl == 'yf') {
                    tickerData = await yfapiCalls.getYF(params)
                } else {
                    tickerData = await yfapiCalls.getRapid(params)
                }
                console.log('tickerData: ', tickerData.data.quoteResponse.result)
                // console.log('store.state.apiUrl: ', store.state.apiUrl)
                if (!tickerData || !tickerData.data.quoteResponse.result[0] || (askedSymbols > 1 && !tickerData.data.quoteResponse.result[1]) || (askedSymbols > 2 && !tickerData.data.quoteResponse.result[2])) {
                    // if (!tickerData || !tickerData.data.quoteResponse.result[0] || !tickerData.data.quoteResponse.result[1] || !tickerData.data.quoteResponse.result[2]) {
                    // console.log('askedSymbols: ', askedSymbols)
                    this.warningMsg = 'Missing some data to proceed - pls try again'
                    return false
                }
                if (tickerData.data.quoteResponse.result[0]['marketState'] == 'REGULAR') {
                    // this.marketopen = true
                    console.log('Market is open')
                } else {
                    // this.marketopen = false
                    // console.log('Market is closed')
                    this.warningMsg = 'The market for this asset is currently closed. Pls try again later.'
                    return false // COMMENT OUT JUST THIS LINE TO OPERATE ON MARKET CLOSED   0917 RESTORE
                }

                let fxAsk = ''
                let fxBid = ''
                if (askedSymbols > 1) {

                    if (tickerData && tickerData.data && tickerData.data.quoteResponse && tickerData.data.quoteResponse.result && tickerData.data.quoteResponse.result[1] && tickerData.data.quoteResponse.result[1]['ask'] && tickerData.data.quoteResponse.result[1]['bid'] && tickerData.data.quoteResponse.result[1]['ask'] >= tickerData.data.quoteResponse.result[1]['bid']) {
                        fxAsk = tickerData.data.quoteResponse.result[1]['ask']
                        fxBid = tickerData.data.quoteResponse.result[1]['bid']
                        console.log('fxAsk8 Ask higher than bid - ok!: ', fxAsk)
                        console.log('typeof tickerData.data.quoteResponse.result[1][ask]: ', typeof tickerData.data.quoteResponse.result[1]['ask'])
                        console.log('fxBid8 Ask higher than bid - ok!: ', fxBid)
                    } else if (tickerData && tickerData.data && tickerData.data.quoteResponse && tickerData.data.quoteResponse.result && tickerData.data.quoteResponse.result[1] && tickerData.data.quoteResponse.result[1]['ask'] && tickerData.data.quoteResponse.result[1]['bid']) {
                        fxAsk = tickerData.data.quoteResponse.result[1]['bid']
                        fxBid = tickerData.data.quoteResponse.result[1]['ask']
                        console.log('fxAsk9 Bid higher than ask - ATT!: ', fxAsk)
                        console.log('typeof tickerData.data.quoteResponse.result[1][bid]: ', typeof tickerData.data.quoteResponse.result[1]['bid'])
                        console.log('fxBid9 Bid higher than ask - ATT!: ', fxBid)
                    } else if (tickerData && tickerData.data && tickerData.data.quoteResponse && tickerData.data.quoteResponse.result && tickerData.data.quoteResponse.result[1] && tickerData.data.quoteResponse.result[1]['regularMarketPrice']) {
                        fxAsk = tickerData.data.quoteResponse.result[1]['regularMarketPrice']
                        fxBid = tickerData.data.quoteResponse.result[1]['regularMarketPrice']
                    } else {
                        this.warningMsg = 'Missing FX data to proceed - pls try again'
                        return false
                    }
                }
                if ((entry.longshort == 'long' && _action == 'reduce') || (entry.longshort == 'short' && _action == 'add')) {
                    this.actionPrice = tickerData.data.quoteResponse.result[0]['bid']
                    if (tickerData.data.quoteResponse.result[0]['currency'] == 'GBp') {
                        this.actionPrice = this.actionPrice / 100
                    }
                    // this.counterPrice = Math.round(tickerData.data.quoteResponse.result[0]['ask'] * 100) / 100
                    // console.log('counterprice: ', this.counterPrice)
                    // console.log('actionprice: ', this.actionPrice)
                    // console.log('counterprice2: ', tickerData.data.quoteResponse.result[0]['ask'])
                    // console.log('actionprice2: ', tickerData.data.quoteResponse.result[0]['bid'])

                    this.pricelabel = 'bid price'
                    if (entry.longshort == 'long') {
                        this.fxlabel = 'bid fx'
                    } else if (entry.longshort == 'short') {
                        this.fxlabel = 'ask fx'
                    }

                    if (entry.currency == store.state.myCur && store.state.myCur != 'USD') {
                        // console.log('It is indeed in my currency')
                        // this.usdAskRate = tickerData.data.quoteResponse.result[1]['ask']
                        this.usdAskRate = fxAsk
                        console.log('fxAsk91: ', fxAsk)
                        console.log('this.usdAskRate1: ', this.usdAskRate)
                    } else if (entry.currency != store.state.myCur && store.state.myCur != 'USD' && entry.currency == 'USD') {
                        // console.log('tickerData.data.quoteResponse.result[1][bid]: ', typeof tickerData.data.quoteResponse.result[1]['bid'])
                        // console.log('tickerData.data.quoteResponse.result[1][bid]: ', tickerData.data.quoteResponse.result[1]['bid'])

                        // this.actionFx = Math.round((tickerData.data.quoteResponse.result[1]['bid'] + Number.EPSILON) * 10000) / 10000
                        this.actionFx = Math.round((fxBid + Number.EPSILON) * 10000) / 10000
                        // this.usdAskRate = tickerData.data.quoteResponse.result[1]['ask']
                        this.usdAskRate = fxAsk
                        console.log('this.actionFx2: ', this.actionFx)
                        console.log('this.usdAskRate2: ', this.usdAskRate)
                    } else if (entry.currency != store.state.myCur && store.state.myCur == 'USD') {
                        // console.log('tickerData.data.quoteResponse.result[1][bid]: ', typeof tickerData.data.quoteResponse.result[1]['bid'])
                        // console.log('tickerData.data.quoteResponse.result[1][bid]: ', tickerData.data.quoteResponse.result[1]['bid'])
                        // this.actionFx = Math.round((tickerData.data.quoteResponse.result[1]['bid'] + Number.EPSILON) * 10000) / 10000
                        if (entry.longshort == 'long') {
                            this.actionFx = Math.round((fxBid + Number.EPSILON) * 10000) / 10000
                        } else if (entry.longshort == 'short') {
                            this.actionFx = Math.round((fxAsk + Number.EPSILON) * 10000) / 10000
                        }
                        // this.actionFx = Math.round((fxBid + Number.EPSILON) * 10000) / 10000
                        console.log('this.actionFx3: ', this.actionFx)
                    } else if (entry.currency != store.state.myCur && store.state.myCur != 'USD' && entry.currency != 'USD') {
                        let fxAsk2 = ''
                        if (tickerData.data.quoteResponse.result[2]['ask'] >= tickerData.data.quoteResponse.result[2]['bid']) {
                            fxAsk2 = tickerData.data.quoteResponse.result[2]['ask']
                            console.log('fxAsk10 Ask higher than bid - ok!: ', fxAsk2)
                        } else {
                            fxAsk2 = tickerData.data.quoteResponse.result[2]['bid']
                            console.log('fxAsk11 Bid higher than ask - ATT!: ', fxAsk2)
                        }

                        // console.log('tickerData.data.quoteResponse.result[1][bid]: ', typeof tickerData.data.quoteResponse.result[1]['bid'])
                        // console.log('tickerData.data.quoteResponse.result[1][bid]: ', tickerData.data.quoteResponse.result[1]['bid'])
                        // this.actionFx = Math.round((tickerData.data.quoteResponse.result[1]['bid'] + Number.EPSILON) * 10000) / 10000
                        if (entry.longshort == 'long') {
                            this.actionFx = Math.round((fxBid + Number.EPSILON) * 10000) / 10000
                        } else if (entry.longshort == 'short') {
                            this.actionFx = Math.round((fxAsk + Number.EPSILON) * 10000) / 10000
                        }

                        // this.actionFx = Math.round((fxBid + Number.EPSILON) * 10000) / 10000
                        // this.usdAskRate = tickerData.data.quoteResponse.result[2]['ask']
                        this.usdAskRate = fxAsk2
                        console.log('this.actionFx: ', this.actionFx)
                        console.log('this.usdAskRate: ', this.usdAskRate)
                    }
                    if (!this.actionPrice || this.actionPrice <= 0 || !this.actionFx || this.actionFx <= 0) {
                        this.warningMsg = 'Market seems open but no bid price is available. Please try later.'
                        return false
                    }
                    // actionUnitPl below only applies to long (which I'm reducing - the shorts I'm adding, so won't use actionUnitPl)
                    this.actionUnitPl = (this.actionPrice * this.actionFx) - (Number(entry.startprice) * Number(entry.startfx))
                    // console.log('entry.actionFx: ', typeof this.actionFx)
                    // console.log('entry.actionFx: ', this.actionFx)
                    // console.log('entry.actionPrice: ', typeof this.actionPrice)
                    // console.log('entry.actionPrice: ', this.actionPrice)
                    // console.log('this.actionUnitPl111: ', typeof this.actionUnitPl)
                    // console.log('this.actionUnitPl111: ', this.actionUnitPl)
                } else if ((entry.longshort == 'long' && _action == 'add') || (entry.longshort == 'short' && _action == 'reduce')) {
                    this.actionPrice = tickerData.data.quoteResponse.result[0]['ask']
                    if (tickerData.data.quoteResponse.result[0]['currency'] == 'GBp') {
                        this.actionPrice = this.actionPrice / 100
                    }
                    // this.counterPrice = Math.round(tickerData.data.quoteResponse.result[0]['bid'] * 100) / 100
                    // console.log('counterprice: ', this.counterPrice)
                    // console.log('actionprice: ', this.actionPrice)
                    // console.log('counterprice2: ', tickerData.data.quoteResponse.result[0]['bid'])
                    // console.log('actionprice2: ', tickerData.data.quoteResponse.result[0]['ask'])
                    this.pricelabel = 'ask price'

                    if (entry.longshort == 'long') { // Here I'm adding to a long
                        this.fxlabel = 'ask fx'
                    } else if (entry.longshort == 'short' && this.actionPrice >= Number(entry.startprice)) { // means you have a LOSS, so will have to buy the entry currency at the ask price in order to cover the loss
                        this.fxlabel = 'ask fx'
                    } else if (entry.longshort == 'short' && this.actionPrice < Number(entry.startprice)) { // means you have a PROFIT, so will have to sell the profit in the entry currency at the bit price of that currency in order to add it to your cash balance
                        this.fxlabel = 'bid fx'
                    }
                    // this.fxlabel = 'ask fx'

                    if (entry.currency == store.state.myCur && store.state.myCur != 'USD') {
                        // this.usdAskRate = tickerData.data.quoteResponse.result[1]['ask']
                        this.usdAskRate = fxAsk
                        console.log('this.usdAskRate4: ', this.usdAskRate)
                    } else if (entry.currency != store.state.myCur && store.state.myCur != 'USD' && entry.currency == 'USD') {
                        // this.actionFx = Math.round((tickerData.data.quoteResponse.result[1]['ask'] + Number.EPSILON) * 10000) / 10000

                        if (entry.longshort == 'long') { // Here I'm adding to a long
                            this.actionFx = Math.round((fxAsk + Number.EPSILON) * 10000) / 10000
                        } else if (entry.longshort == 'short' && this.actionPrice >= Number(entry.startprice)) { // means you have a LOSS, so will have to buy the entry currency at the ask price in order to cover the loss
                            this.actionFx = Math.round((fxAsk + Number.EPSILON) * 10000) / 10000
                        } else if (entry.longshort == 'short' && this.actionPrice < Number(entry.startprice)) { // means you have a PROFIT, so will have to sell the profit in the entry currency at the bit price of that currency in order to add it to your cash balance
                            this.actionFx = Math.round((fxBid + Number.EPSILON) * 10000) / 10000
                        }

                        // this.usdAskRate = tickerData.data.quoteResponse.result[1]['ask']
                        this.usdAskRate = fxAsk
                        console.log('this.actionFx5: ', this.actionFx)
                        console.log('this.usdAskRate5: ', this.usdAskRate)
                    } else if (entry.currency != store.state.myCur && store.state.myCur == 'USD') {
                        // this.actionFx = Math.round((tickerData.data.quoteResponse.result[1]['ask'] + Number.EPSILON) * 10000) / 10000

                        if (entry.longshort == 'long') {
                            this.actionFx = Math.round((fxAsk + Number.EPSILON) * 10000) / 10000
                        } else if (entry.longshort == 'short' && this.actionPrice >= Number(entry.startprice)) { // means you have a LOSS, so will have to buy the entry currency at the ask price in order to cover the loss
                            this.actionFx = Math.round((fxAsk + Number.EPSILON) * 10000) / 10000
                        } else if (entry.longshort == 'short' && this.actionPrice < Number(entry.startprice)) { // means you have a PROFIT, so will have to sell the profit in the entry currency at the bit price of that currency in order to add it to your cash balance
                            this.actionFx = Math.round((fxBid + Number.EPSILON) * 10000) / 10000
                        }

                        // this.actionFx = Math.round((fxAsk + Number.EPSILON) * 10000) / 10000
                        console.log('this.actionFx6: ', this.actionFx)
                    } else if (entry.currency != store.state.myCur && store.state.myCur != 'USD' && entry.currency != 'USD') {

                        let fxAsk3 = ''
                        if (tickerData.data.quoteResponse.result[2]['ask'] >= tickerData.data.quoteResponse.result[2]['bid']) {
                            fxAsk3 = tickerData.data.quoteResponse.result[2]['ask']
                        } else {
                            fxAsk3 = tickerData.data.quoteResponse.result[2]['bid']
                        }
                        // this.actionFx = Math.round((tickerData.data.quoteResponse.result[1]['ask'] + Number.EPSILON) * 10000) / 10000

                        if (entry.longshort == 'long') {
                            this.actionFx = Math.round((fxAsk + Number.EPSILON) * 10000) / 10000
                        } else if (entry.longshort == 'short' && this.actionPrice >= Number(entry.startprice)) { // means you have a LOSS, so will have to buy the entry currency at the ask price in order to cover the loss
                            this.actionFx = Math.round((fxAsk + Number.EPSILON) * 10000) / 10000
                        } else if (entry.longshort == 'short' && this.actionPrice < Number(entry.startprice)) { // means you have a PROFIT, so will have to sell the profit in the entry currency at the bit price of that currency in order to add it to your cash balance
                            this.actionFx = Math.round((fxBid + Number.EPSILON) * 10000) / 10000
                        }
                        // this.actionFx = Math.round((fxAsk + Number.EPSILON) * 10000) / 10000
                        // this.usdAskRate = tickerData.data.quoteResponse.result[2]['ask']
                        this.usdAskRate = fxAsk3
                        console.log('this.actionFx7: ', this.actionFx)
                        console.log('this.usdAskRate7: ', this.usdAskRate)
                    }
                    if (!this.actionPrice || this.actionPrice <= 0 || !this.actionFx || this.actionFx <= 0) {
                        this.warningMsg = 'Market seems open but no ask price is available. Please try later.'
                        return false
                    }
                    // actionUnitPl below only applies to shorts here (which I'm reducing - the long I'm adding, so won't use actionUnitPl)
                    this.actionUnitPl = (Number(entry.startprice) - this.actionPrice) * this.actionFx // actionFx has been defined before, based on whether it's a profit or a loss

                    // this.actionUnitPl = (Number(entry.startprice) * Number(entry.startfx)) - (this.actionPrice * this.actionFx)

                }
                this.actionUnitPlUsd = this.actionUnitPl / this.usdAskRate // TODO: recheck whether this should always be the USD ask rate
                return true
            } catch (error) {
                if (store.state.alertMessage == 1) {
                    return alert(error)
                } else {
                    return console.log('errorTI21: ', error)
                }
            }

        },

        async getCryptoPriceFromApi(entry, _action) { // This is needed for individual assets if I want to close, reduce or add to the position

            try {
                let asset = entry.assetname
                // let cryptoId = null // Notice, with null -> if(cryptoId) doesn't trigger -> if(!cryptoId) triggers
                let id = asset.slice(asset.indexOf(' - CRYPTO - ') + 12)

                const params = {
                    ids: id,
                    vs_currencies: 'usd',
                    precision: 10,
                }

                const tickerData = await cgapiCalls.getCryptoPrice(params)
                console.log('tickerData CG: ', tickerData.data)

                if (!tickerData || !tickerData.data || !tickerData.data[id] || !tickerData.data[id]['usd']) {
                    this.warningMsg = 'Missing price data to proceed - pls try again'
                    return false
                } else {
                    this.actionPrice = tickerData.data[id]['usd']
                }

                let fxAsk = ''
                let fxBid = ''
                this.actionFx = 1.0000
                this.usdAskRate = 1.0000

                let fxData = []
                // let fx = null

                let fxParams = {}
                if (store.state.myCur != 'USD') {
                    fxParams['symbols'] = store.state.myCur + '=X'

                    if (store.state.apiUrl == 'yf') {
                        fxData = await yfapiCalls.getYF(fxParams)
                    } else {
                        fxData = await yfapiCalls.getRapid(fxParams)
                    }

                    if (fxData.data && fxData.data.quoteResponse && fxData.data.quoteResponse.result && fxData.data.quoteResponse.result[0] && fxData.data.quoteResponse.result[0]['ask'] && fxData.data.quoteResponse.result[0]['bid'] && fxData.data.quoteResponse.result[0]['ask'] >= fxData.data.quoteResponse.result[0]['bid']) {
                        console.log('fxData:', fxData)
                        fxAsk = fxData.data.quoteResponse.result[0]['ask']
                        fxBid = fxData.data.quoteResponse.result[0]['bid']
                    } else if (fxData.data && fxData.data.quoteResponse && fxData.data.quoteResponse.result && fxData.data.quoteResponse.result[0] && fxData.data.quoteResponse.result[0]['ask'] && fxData.data.quoteResponse.result[0]['bid']) {
                        fxAsk = fxData.data.quoteResponse.result[0]['bid']
                        fxBid = fxData.data.quoteResponse.result[0]['ask']
                    } else if (fxData.data && fxData.data.quoteResponse && fxData.data.quoteResponse.result && fxData.data.quoteResponse.result[0] && fxData.data.quoteResponse.result[0]['regularMarketPrice']) {
                        fxAsk = fxData.data.quoteResponse.result[0]['regularMarketPrice']
                        fxBid = fxData.data.quoteResponse.result[0]['regularMarketPrice']
                    } else {
                        this.warningMsg = 'Missing FX data to proceed - pls try again'
                        return false
                    }
                }

                if ((entry.longshort == 'long' && _action == 'reduce') || (entry.longshort == 'short' && _action == 'add')) {
                    // this.actionPrice = tickerData.data[id]['usd']
                    this.pricelabel = 'bid price'
                    if (entry.longshort == 'long') {
                        this.fxlabel = 'bid fx'
                    } else if (entry.longshort == 'short') {
                        this.fxlabel = 'ask fx'
                    }

                    if (store.state.myCur != 'USD') {
                        this.actionFx = Math.round((fxBid + Number.EPSILON) * 10000) / 10000
                        this.usdAskRate = fxAsk
                    }

                    if (!this.actionPrice || this.actionPrice <= 0 || !this.actionFx || this.actionFx <= 0) {
                        this.warningMsg = 'Market seems open but no bid price is available. Please try later.'
                        return false
                    }

                    this.actionUnitPl = (this.actionPrice * this.actionFx) - (Number(entry.startprice) * Number(entry.startfx))

                } else if ((entry.longshort == 'long' && _action == 'add') || (entry.longshort == 'short' && _action == 'reduce')) {
                    // this.actionPrice = tickerData.data[id]['usd']

                    this.pricelabel = 'ask price'

                    if (entry.longshort == 'long') { // Here I'm adding to a long
                        this.fxlabel = 'ask fx'
                    } else if (entry.longshort == 'short' && this.actionPrice >= Number(entry.startprice)) { // means you have a LOSS, so will have to buy the entry currency at the ask price in order to cover the loss
                        this.fxlabel = 'ask fx'
                    } else if (entry.longshort == 'short' && this.actionPrice < Number(entry.startprice)) { // means you have a PROFIT, so will have to sell the profit in the entry currency at the bit price of that currency in order to add it to your cash balance
                        this.fxlabel = 'bid fx'
                    }

                    if (store.state.myCur != 'USD') {

                        if (entry.longshort == 'long') { // Here I'm adding to a long
                            this.actionFx = Math.round((fxAsk + Number.EPSILON) * 10000) / 10000
                        } else if (entry.longshort == 'short' && this.actionPrice >= Number(entry.startprice)) { // means you have a LOSS, so will have to buy the entry currency at the ask price in order to cover the loss
                            this.actionFx = Math.round((fxAsk + Number.EPSILON) * 10000) / 10000
                        } else if (entry.longshort == 'short' && this.actionPrice < Number(entry.startprice)) { // means you have a PROFIT, so will have to sell the profit in the entry currency at the bit price of that currency in order to add it to your cash balance
                            this.actionFx = Math.round((fxBid + Number.EPSILON) * 10000) / 10000
                        }

                        this.usdAskRate = fxAsk
                    }

                    if (!this.actionPrice || this.actionPrice <= 0 || !this.actionFx || this.actionFx <= 0) {
                        this.warningMsg = 'Market seems open but no ask price is available. Please try later.'
                        return false
                    }

                    this.actionUnitPl = (Number(entry.startprice) - this.actionPrice) * this.actionFx // actionFx has been defined before, based on whether it's a profit or a loss

                }
                this.actionUnitPlUsd = this.actionUnitPl / this.usdAskRate // TODO: recheck whether this should always be the USD ask rate
                return true
            } catch (error) {
                if (store.state.alertMessage == 1) {
                    return alert(error)
                } else {
                    return console.log('errorTI212: ', error)
                }
            }

        },

        async closePosition(entry, index) {

            if (!entry.reduceQty || entry.reduceQty <= 0 || Math.round(entry.reduceQty * 100) / 100 != entry.reduceQty) {
                return this.errorMsg = 'Please enter a positive quantity with max 2 decimals'
            } else if (entry.reduceQty > entry.quantity) {
                return this.errorMsg = 'Quantity entered higher than the available one'
            } else if (entry.longshort == 'short' && ((this.actionPrice - Number(entry.startprice)) * this.actionFx >= Number(entry.startprice) * Number(entry.startfx))) {
                console.log('found short to stop out')
                return await this.shortStopOut(entry, index)
            } else if (entry.reduceQty < entry.quantity) {
                return this.reducePosition(entry, index)
            }
            try {
                let entryId = entry.id
                console.log('entry.id: ', entry.id)
                console.log('entryId: ', entryId)
                let actionPl = this.actionUnitPl * entry.quantity
                let endpl = Math.round((Number(entry.endpl) + actionPl) * 100) / 100
                let actionPlUsd = this.actionUnitPlUsd * entry.reduceQty
                let usdpl = Math.round((Number(entry.usdpl) + actionPlUsd) * 100) / 100
                console.log('pl components: ', this.actionUnitPl, ' ', entry.quantity, ' ', entry.endpl, ' ', actionPl, ' ', entry.reduceQty, ' ', this.actionUnitPlUsd, ' ', entry.usdpl, ' ', actionPlUsd)
                let updatedComment = null
                let cutPos = entry.comment.indexOf("INITIAL COMMENT:")
                let tradeend = entry.comment.substr(0, cutPos).lastIndexOf('\n\n')
                let tradesstr = entry.comment.substr(0, tradeend)
                let commentstr = entry.comment.substr(tradeend)
                let tradeCurrency = ''
                if (entry.currencysymbol) {
                    tradeCurrency = entry.currencysymbol.slice(-5, -2).toLowerCase()
                } else {
                    tradeCurrency = entry.currency.toLowerCase()
                }
                let myDate = new Date()
                if (entry.longshort == 'long') {
                    updatedComment = `${tradesstr}${'\n'}S: ${entry.quantity}; pri ${this.actionPrice}; fx ${this.actionFx}; ${myDate.getFullYear()}-${('0'+(myDate.getMonth()+1)).slice(-2)}-${('0'+myDate.getDate()).slice(-2)}; p/l ${tradeCurrency}${(Math.round(actionPl*100)/100).toLocaleString('en-US')}${commentstr}`
                } else if (entry.longshort == 'short') {
                    updatedComment = `${tradesstr}${'\n'}B: ${entry.quantity}; pri ${this.actionPrice}; fx ${this.actionFx}; ${myDate.getFullYear()}-${('0'+(myDate.getMonth()+1)).slice(-2)}-${('0'+myDate.getDate()).slice(-2)}; p/l ${tradeCurrency}${(Math.round(actionPl*100)/100).toLocaleString('en-US')}${commentstr}`
                }

                let returnAmount = Number(entry.startvalue) + (this.actionUnitPl * entry.quantity)

                let params = {
                    'closed': true,
                    'quantity': 0,
                    'timeclosed': new Date().toISOString().slice(0, 16).replace('T', ' @'),
                    'endprice': Math.round(this.actionPrice * 10000000000) / 10000000000,
                    'endfx': Math.round(this.actionFx * 10000) / 10000,
                    'endpl': endpl,
                    'usdpl': usdpl,
                    'comment': updatedComment,
                    'lastprice': Math.round(this.actionPrice * 10000000000) / 10000000000,
                    'amount': returnAmount,
                    'actionpl': actionPl,
                    'poschg': 1, // the negative sign is added in the backend
                }
                const patchedpos = await apiCalls.patchEntry('/positions/' + entryId + '/', params)
                if (patchedpos) {
                    console.log('patchedpos: ', patchedpos.data)
                } else {
                    console.log('position not patched')
                }

                //TODO: SHOULD ONLY PASS returnAmount and actionPl and openpos = 0/-1, and let the back end handle (add returnAmount to cash, deduct returnAmount from userportfolio, add actionPl to accruedPl, deduct 1 from openpos)
                ///////////////////// BELOW IS NOW DONE AT THE BACK-END /////////////////////////////////////
                // let participant = {}
                // participant['usercash'] = Math.round((this.usercash + returnAmount) * 100) / 100
                // participant['accruedpl'] =  Math.round(actionPl * 100) / 100
                // this.usercash = participant['usercash']
                // // let portfolioReduction = entry.quantity * Number(entry.lastprice) * this.actionFx  // This is an approximation because it uses the actionFX rather than the lastFx used to calculate the overnight value.
                // participant['userportfolio'] = Math.round((this.userportfolio - returnAmount) * 100) / 100 // TODO: MAY CHANGE THIS: IT MUST BE REDUCED BY THE LAST VALUE WHICH IS QUANTITY * LASTPRICE * LASTFX. RIGHT NOW IT IS AN APPROXIMATION - WILL BE MADE FULLY CORRECT DURING FIRST OVERNIGHT PORTFOLIO VALUATION
                // participant['openpos'] = this.openpos - 1
                // this.openpos -= 1
                // await apiCalls.patchEntry('/participants/' + store.state.myPid + '/', participant)
                ////////////////////////////////////////////////////////////////////////////////////////////////

                // let partTrade = {}
                // partTrade['usercash'] = returnAmount
                // partTrade['accruedpl'] = actionPl
                // partTrade['openpos'] = -1
                // const patchedPartTrade = await apiCalls.patchEntry('/participanttrade/' + store.state.myPid + '/', partTrade)
                // console.log('patchedPartTrade: ', patchedPartTrade)

                // let portfolioReduction = entry.quantity * Number(entry.lastprice) * this.actionFx  // This is an approximation because it uses the actionFX rather than the lastFx used to calculate the overnight value.
                this.usercash = Math.round((this.usercash + returnAmount) * 100) / 100
                this.openpos -= 1
                this.accruedpl = Math.round((this.accruedpl + actionPl) * 100) / 100

                this.showCard[index] = false
                this.showComment[index] = false
                this.updatedEntries.splice(index, 1)

                // Delete from taken Assets in Club *********************
                // const deleteAssetname = entry.assetname.split(' ')[0]
                // await this.getTakenassets()
                // delete this.takenassetsObj[deleteAssetname]
                // const takenassetsString = JSON.stringify(this.takenassetsObj)
                // const params2 = {
                //     'takenassets': takenassetsString
                // }
                // console.log('params', params2)
                // await apiCalls.patchEntry('/clubs/' + store.state.myClub + '/', params2)
                // ************ END ************************
                await this.getParticipant()
                await this.getAssets()

                this.hideReduceModal()
            } catch (error) {
                if (store.state.alertMessage == 1) {
                    return alert(error)
                } else {
                    return console.log('errorTI13: ', error)
                }
            }
        },
        async reducePosition(entry, index) {
            try {
                // await this.getCurrentPriceFromApi(entry)
                let entryId = entry.id
                // if (entry.longshort == 'short' && this.actionPrice >= (Number(entry.startprice) * 2)) {
                //     console.log('found short to stop out')
                //     return await this.shortStopout(entry, index)
                // }
                let actionPl = this.actionUnitPl * entry.reduceQty
                let endpl = Math.round((Number(entry.endpl) + actionPl) * 100) / 100
                let actionPlUsd = this.actionUnitPlUsd * entry.reduceQty
                let usdpl = Math.round((Number(entry.usdpl) + actionPlUsd) * 100) / 100
                console.log('pl components: ', this.actionUnitPl, ' ', entry.reduceQty, ' ', this.actionUnitPlUsd, ' ', entry.usdpl, ' ', actionPlUsd, ' ', usdpl, ' ', this.actionPrice)
                let updatedComment = null
                let cutPos = entry.comment.indexOf("INITIAL COMMENT:")
                let tradeend = entry.comment.substr(0, cutPos).lastIndexOf('\n\n')
                let tradesstr = entry.comment.substr(0, tradeend)
                let commentstr = entry.comment.substr(tradeend)
                let tradeCurrency = ''
                if (entry.currencysymbol) {
                    tradeCurrency = entry.currencysymbol.slice(-5, -2).toLowerCase()
                } else {
                    tradeCurrency = entry.currency.toLowerCase()
                }
                let myDate = new Date()
                if (entry.longshort == 'long') {
                    updatedComment = `${tradesstr}${'\n'}S: ${entry.reduceQty}; pri ${this.actionPrice}; fx ${this.actionFx}; ${myDate.getFullYear()}-${('0'+(myDate.getMonth()+1)).slice(-2)}-${('0'+myDate.getDate()).slice(-2)}; ${tradeCurrency} ${(Math.round(actionPl*100)/100).toLocaleString('en-US')}${commentstr}`
                } else if (entry.longshort == 'short') {
                    updatedComment = `${tradesstr}${'\n'}B: ${entry.reduceQty}; pri ${this.actionPrice}; fx ${this.actionFx}; ${myDate.getFullYear()}-${('0'+(myDate.getMonth()+1)).slice(-2)}-${('0'+myDate.getDate()).slice(-2)}; ${tradeCurrency} ${(Math.round(actionPl*100)/100).toLocaleString('en-US')}${commentstr}`
                }

                let returnAmount = (Number(entry.startprice) * Number(entry.startfx) * entry.reduceQty) + (this.actionUnitPl * entry.reduceQty)

                let params = {
                    'quantity': entry.quantity - entry.reduceQty,
                    'startvalue': Math.round(Number(entry.startprice) * Number(entry.startfx) * (entry.quantity - entry.reduceQty) * 100) / 100,
                    'endpl': endpl,
                    'usdpl': usdpl,
                    'comment': updatedComment,
                    'lastprice': Math.round(this.actionPrice * 10000000000) / 10000000000,
                    'amount': returnAmount,
                    'actionpl': actionPl,
                    'poschg': 0,
                }
                console.log('params: ', params)
                const patchedpos = await apiCalls.patchEntry('/positions/' + entryId + '/', params)
                if (patchedpos) {
                    console.log('patchedpos: ', patchedpos.data)
                } else {
                    console.log('position not patched')
                }

                //TODO: SHOULD ONLY PASS returnAmount and actionPl and openpos = 0/-1, and let the back end handle (add returnAmount to cash, deduct returnAmount from userportfolio, add actionPl to accruedPl, deduct 1 from openpos)

                ///////////////////////////// BELOW NOW HANDLED AT BACKEND ////////////////////////////////////////////////////
                // let participant = {}
                // participant['usercash'] = Math.round((this.usercash + returnAmount) * 100) / 100
                // participant['accruedpl'] =  Math.round(actionPl * 100) / 100
                // this.usercash = participant['usercash']
                // participant['userportfolio'] = Math.round((this.userportfolio - returnAmount) * 100) / 100 // TODO: MAY CHANGE THIS: IT MUST BE REDUCED BY THE LAST VALUE WHICH IS QUANTITY * LASTPRICE * LASTFX. RIGHT NOW IT IS AN APPROXIMATION - WILL BE MADE FULLY CORRECT DURING FIRST OVERNIGHT PORTFOLIO VALUATION
                // const patchedpart = await apiCalls.patchEntry('/participants/' + store.state.myPid + '/', participant)
                // if (patchedpart) {
                //     console.log('patchedpart: ', patchedpart.data)
                // } else {
                //     console.log('participant not patched')
                // }
                //////////////////////////////////////////////////////////////////////////////////////////////////////

                //TODO: SHOULD ONLY PASS returnAmount and actionPl and openpos = 0/-1, and let the back end handle (add returnAmount to cash, deduct returnAmount from userportfolio, add actionPl to accruedPl, deduct 1 from openpos)
                // let partTrade = {}
                // partTrade['usercash'] = returnAmount
                // partTrade['accruedpl'] = actionPl
                // partTrade['openpos'] = 0
                // const patchedPartTrade = await apiCalls.patchEntry('/participanttrade/' + store.state.myPid + '/', partTrade)
                // console.log('patchedPartTrade: ', patchedPartTrade)

                this.usercash = Math.round((this.usercash + returnAmount) * 100) / 100

                this.showCard[index] = false
                this.showComment[index] = false

                // ****************************** COMMENTED OUT THE CREATION OF CLOSED TRADE ON REDUCTION **********************
                // delete entry.pricetimestamp
                // let updatedCommentClosed = null
                // if (entry.longshort == 'long') {
                //     updatedCommentClosed = `${entry.comment}${'/n'}-Bought ${entry.quantity} @ ${entry.currency} ${entry.startprice} on ${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate()}`
                // } else if (entry.longshort == 'short') {
                //     updatedCommentClosed = `${entry.comment}${'/n'}-Sold ${entry.quantity} @ ${entry.currency} ${entry.startprice} on ${new Date().getFullYear()}-${new Date().getMonth()+1}-${new Date().getDate()}`
                // }
                // let closedParams = {
                //     ...entry, // All the other fields, like startprice, startfx, etc are the same as already in entry
                //     'closed': true,
                //     'quantity': entry.reduceQty,
                //     'startvalue': (Math.round(Number(entry.startprice) * Number(entry.startfx) * (entry.reduceQty) * 100) / 100).toFixed(2),
                //     'timeclosed': new Date().toISOString().slice(0, 16).replace('T', ' @'),
                //     'endprice': (Math.round(this.actionPrice * 100) / 100).toFixed(2),
                //     'endfx': (Math.round(this.actionFx * 10000) / 10000).toFixed(4),
                //     'endpl': (Math.round(this.actionUnitPl * entry.reduceQty * 100) / 100).toFixed(2),
                //     'comment': updatedCommentClosed
                // }
                // await apiCalls.postEntry('/positions/', closedParams)

                await this.getParticipant()
                await this.getAssets()

                this.hideReduceModal()
            } catch (error) {
                if (store.state.alertMessage == 1) {
                    return alert(error)
                } else {
                    return console.log('errorTI14: ', error)
                }
            }
        },
        async addToPosition(entry, index) {
            console.log('add quantity:', entry.addQty)
            console.log('typeof entry.quantity', typeof entry.quantity)
            // if (entry.addQty < 100000) {
            //     return
            // }
            try {
                if (!entry.addQty || entry.addQty <= 0 || Math.round(entry.addQty * 100) / 100 != entry.addQty) {
                    console.log('Math.round(entry.addQty, 2):', Math.round(entry.addQty, 2))
                    console.log('entry.addQty:', entry.addQty)
                    return this.errorMsg = 'Please enter a positive quantity with max 2 decimals'
                }
                if ((this.actionPrice * this.actionFx * entry.addQty) > this.usercash) {
                    return this.errorMsg = 'Maximum buying power: ' + (Math.round(this.usercash * 100 / (1 + store.state.tradingFee)) / 100).toLocaleString('en-US') + ' ' + store.state.myCur // Trading fee 1.01
                }
                if (entry.longshort == 'short' && ((this.actionPrice - Number(entry.startprice)) * this.actionFx >= Number(entry.startprice) * Number(entry.startfx))) {
                    console.log('found short to stop out')
                    return await this.shortStopOut(entry, index)
                }
                let entryId = entry.id
                let startprice = (Number(entry.startprice) * entry.quantity + this.actionPrice * entry.addQty) / (entry.quantity + entry.addQty)
                let startfx = (Number(entry.startfx) * entry.quantity + this.actionFx * entry.addQty) / (entry.quantity + entry.addQty)
                console.log('startfx components: ', entry.startfx, ' ', entry.quantity, ' ', this.actionFx, ' ', entry.addQty)
                console.log('startprice components: ', entry.startprice, ' ', entry.quantity, ' ', this.actionPrice, ' ', entry.addQty)
                console.log('startfx startprice: ', startfx, ' ', startprice)

                let startvalue = (entry.quantity + entry.addQty) * startprice * startfx
                let updatedComment = null
                let cutPos = entry.comment.indexOf("INITIAL COMMENT:")

                let tradeend = entry.comment.substr(0, cutPos).lastIndexOf('\n\n')
                let tradesstr = entry.comment.substr(0, tradeend)
                let commentstr = entry.comment.substr(tradeend)
                let myDate = new Date()
                if (entry.longshort == 'long') {
                    updatedComment = `${tradesstr}${'\n'}B: ${entry.addQty}; pri ${this.actionPrice}; fx ${this.actionFx}; ${myDate.getFullYear()}-${('0'+(myDate.getMonth()+1)).slice(-2)}-${('0'+myDate.getDate()).slice(-2)}${commentstr}`
                } else if (entry.longshort == 'short') {
                    updatedComment = `${tradesstr}${'\n'}S: ${entry.addQty}; pri ${this.actionPrice}; fx ${this.actionFx}; ${myDate.getFullYear()}-${('0'+(myDate.getMonth()+1)).slice(-2)}-${('0'+myDate.getDate()).slice(-2)}${commentstr}`
                }
                let costAmount = this.actionPrice * this.actionFx * entry.addQty
                let params = {
                    'quantity': entry.quantity + entry.addQty,
                    'startprice': Math.round(startprice * 10000000000) / 10000000000,
                    'lastprice': Math.round(startprice * 10000000000) / 10000000000, // was this.counterprice but no need to start negative, better see the value of the position if had to take again
                    'startfx': Math.round(startfx * 10000) / 10000,
                    'startvalue': Math.round(startvalue * 100) / 100,
                    'comment': updatedComment,
                    'amount': costAmount,
                    'feerate': store.state.tradingFee,
                }
                console.log('params: ', params)
                const patchedpos = await apiCalls.patchEntry('/positions/' + entryId + '/', params)
                if (patchedpos) {
                    console.log('patchedpos: ', patchedpos.data)
                } else {
                    console.log('position not patched')
                }

                //TODO: SHOULD ONLY PASS deductAmount, tradingFee and openpos = 0/+1 and let the back end handle (subtract deductAmount*(1+tradingFee) from cash, add deductAmount to userportfolio, adjust openPos)
                ////////////// This is now done at the backend!/////////////////////////////
                // let participant = {}
                // participant['usercash'] = Math.round((this.usercash - (costAmount * (1 + store.state.tradingFee))) * 100) / 100 // Trading fee 1.01
                // this.usercash = participant['usercash']
                // participant['userportfolio'] = Math.round((this.userportfolio + costAmount) * 100) / 100
                // const patchedpart = await apiCalls.patchEntry('/participants/' + store.state.myPid + '/', participant)
                // if (patchedpart) {
                //     console.log('patchedpart: ', patchedpart.data)
                // } else {
                //     console.log('participant not patched')
                // }
                /////////////////////////////////////////////////////////////////////////////////
                // let partTrade = {}
                // partTrade['usercash'] = deductAmount
                // partTrade['fees'] = store.state.tradingFee
                // partTrade['openpos'] = 0
                // const patchedPartTrade = await apiCalls.patchEntry('/participanttrade/' + store.state.myPid + '/', partTrade)
                // console.log('patchedPartTrade: ', patchedPartTrade)

                this.usercash = Math.round((this.usercash - (costAmount * (1 + store.state.tradingFee))) * 100) / 100 // Trading fee 0.01

                this.showCard[index] = false
                this.showComment[index] = false
                // delete entry.pricetimestamp
                // 'pricetimestamp': new Date().toISOString().slice(0, 16).replace('T', ' @'),

                await this.getParticipant()
                await this.getAssets()

                this.hideAddModal()
            } catch (error) {
                if (store.state.alertMessage == 1) {
                    return alert(error)
                } else {
                    return console.log('errorTI20: ', error)
                }
            }
        },
        async shortStopOut(entry, index) {
            try {
                let entryId = entry.id
                let actionPl = (-1) * Number(entry.startvalue)
                let endpl = Math.round((Number(entry.endpl) + actionPl) * 100) / 100
                let actionPlUsd = actionPl / this.usdAskRate
                let usdpl = Math.round((Number(entry.usdpl) + actionPlUsd) * 100) / 100
                let updatedComment = null
                let cutPos = entry.comment.indexOf("INITIAL COMMENT:")
                let tradeend = entry.comment.substr(0, cutPos).lastIndexOf('\n\n')
                let tradesstr = entry.comment.substr(0, tradeend)
                let commentstr = entry.comment.substr(tradeend)
                let tradeCurrency = ''
                if (entry.currencysymbol) {
                    tradeCurrency = entry.currencysymbol.slice(-5, -2).toLowerCase()
                } else {
                    tradeCurrency = entry.currency.toLowerCase()
                }
                let myDate = new Date()
                updatedComment = `${tradesstr}${'\n'}${myDate}: Remaining quantity stopped out at price ${(Math.round(Number(entry.startprice) * 2 * 100)/100).toLocaleString('en-US')} ${entry.currency.toLowerCase()}; ${tradeCurrency} -${entry.startvalue}${commentstr}`
                // updatedComment = `${tradesstr}${'\n'}B: ${entry.reduceQty}; pri ${this.actionPrice}; fx ${this.actionFx}; ${myDate.getFullYear()}-${('0'+(myDate.getMonth()+1)).slice(-2)}-${('0'+myDate.getDate()).slice(-2)}; ${tradeCurrency} ${(Math.round(actionPl*100)/100).toLocaleString('en-US')}${commentstr}`
                let endprice = Math.round(Number(entry.startprice) * (1 + Number(entry.startfx) / this.actionFx) * 10000) / 10000 // must fulfill the condition that the unit loss = (actionprice-startprice)*actionFX is equal to the unit margin = startprice*startfx

                let params = {
                    'closed': true,
                    'quantity': 0,
                    'timeclosed': new Date().toISOString().slice(0, 16).replace('T', ' @'),
                    'endprice': Math.round(Number(entry.startprice) * (1 + Number(entry.startfx) / this.actionFx) * 10000) / 10000,
                    'endfx': Math.round(this.actionFx * 10000) / 10000,
                    'endpl': endpl,
                    'usdpl': usdpl,
                    'comment': updatedComment,
                    'lastprice': endprice,
                }
                await apiCalls.patchEntry('/positions/' + entryId + '/', params)
                // console.log(Resp) // No particular info in the Response from delete
                // let returnAmount = Number(entry.startvalue) + (this.actionUnitPl * entry.quantity)
                let participant = {}
                // participant['usercash'] = Math.round((this.usercash + returnAmount) * 100) / 100
                // this.usercash = participant['usercash']
                let portfolioReduction = entry.quantity * Number(entry.lastprice) * this.actionFx // this.actionFx is entry.currency / mycurrency =X,  therefore it is correct TODO: SURE THIS IS MULTIPLICATION FOR BOTH LONGS AND SHORTS???
                // participant['userportfolio'] = Math.round((this.userportfolio - returnAmount) * 100) / 100 // TODO: CHANGE THIS: IT MUST BE REDUCED BY THE LAST VALUE WHICH IS QUANTITY * LASTPRICE * LASTFX
                participant['userportfolio'] = Math.round((this.userportfolio - portfolioReduction) * 100) / 100 // it's an approximation: using actionfx instead of lastfx. But it's temporary, will be corrected overnight to last values.
                participant['openpos'] = this.openpos - 1
                this.openpos -= 1
                await apiCalls.patchEntry('/participants/' + store.state.myPid + '/', participant)

                this.showCard[index] = false
                this.showComment[index] = false
                this.updatedEntries.splice(index, 1)

                this.hideReduceModal()
            } catch (error) {
                if (store.state.alertMessage == 1) {
                    return alert(error)
                } else {
                    return console.log('errorTI131: ', error)
                }
            }

        },
        async getParticipant() {
            try {
                if (store.state.particId != null && store.state.particId != store.state.myPid) { // Looking into a Participant other than myself
                    this.particId = store.state.particId
                    this.showCloseLink = false
                } else if (store.state.myPid != null) {
                    this.particId = store.state.myPid
                    this.showCloseLink = true
                } else {
                    console.log('Missing both store.state.particId and store.state.myPid in TablePortfolio - forcing logout')
                    await this.setRfrsh('/login')
                    return await this.setAlertMsg(`Network error. Please login again.${'\n\n'}If the error persists, please inform the administrators. Thank you.`)
                }
                let participantData = {}
                if (store.state.page != '/investor' || store.state.investorData == null) {
                    console.log('AAAATTTT investorData not used in portfolio getParticipant: ', store.state.investorData)
                    let Entries = null
                    if (this.particId != null) {
                        Entries = await apiCalls.getEntries('/participants/' + this.particId + '/', {})
                    } else {
                        console.log('this.particId is null - forcing logout')
                        await this.setRfrsh('/login')
                        return await this.setAlertMsg(`Network error. Please login again.${'\n\n'}If the error persists, please inform the administrators. Thanks.`)
                    }
                    if (Entries != null) {
                        participantData = Entries.data
                    }
                } else if (store.state.page == '/investor' && store.state.investorData != null) {
                    participantData = store.state.investorData
                    console.log('AAAATTTT investorData used in portfolio getParticipant: ', store.state.investorData)
                }
                if (Object.keys(participantData).length != 0) { // means participantData is not empty
                    if (!localStorage.getItem('paic') || store.state.particIcon == null) { // this is the case when it is routing from View buttom in a notification and misses the icon of the notifier
                        localStorage.setItem('paic', participantData['usericon'])
                        await this.setParticIcon(participantData['usericon'])
                    }
                    this.usercash = Number(participantData['usercash'])
                    this.startcash = Number(participantData['startcash'])
                    this.userportfolio = Number(participantData['userportfolio'])
                    this.openpos = Number(participantData['openpos'])
                    this.baseCurrency = participantData['basecurrency']
                    // this.hideComments = participantData['stopcomments'] // DELETE
                    this.hideComments = participantData['hidecomments'] //RESTORE                    
                    this.stopComments = participantData['stopcomments']
                    this.accruedpl = participantData['accruedpl']
                    this.fees = participantData['fees']
                    this.dividends = participantData['dividends']
                    console.log('accruedpl: ', this.accruedpl)
                    console.log('fees: ', this.fees)
                    console.log('dividends: ', this.dividends)
                    console.log('startcash: ', this.startcash)
                    console.log('usercash: ', this.usercash)

                } else {
                    console.log('could not getParticipant in TablePortfolio')
                    await this.setRfrsh('/login')
                    return await this.setAlertMsg(`Network error. Please login again.${'\n\n'}If the error persists, please inform the administrators.`)
                }
            } catch (error) {
                if (store.state.alertMessage == 1) {
                    return alert(error)
                } else {
                    return console.log('errorTI16: ', error)
                }
            }
        },
        addComment(index) {
            this.textArea[index] = true
            // document.getElementById('floatingTextarea-' + index).focus()
            setTimeout(() => { // NEW 20230308  // Seems that in reactive languages like React, Vue etc focus command doesn't work if you don't place inside setTimeout
                document.getElementById('posfloatingTextarea-' + index).focus()
                // document.getElementById('poscomment-' + index).scrollIntoView({
                document.getElementById('posfloatingTextarea-' + index).scrollIntoView({
                    behavior: 'smooth',
                    block: 'center'
                })
            }, 0)
        },
        async enterComment(entry, index) { // TODO: validate the comment!!! 
            if (!this.newcomment[index]) {
                return
            }
            // if (!entry.extracomments) {   // NO NEED BECAUSE WHEN TOGGLING HAVE ALREADY DEFINED THIS.UPDATEDCOMMENT[INDEX]
            //     this.updatedcomment[index] = `${entry.comment}${'\n'}`
            // }
            let newComment = {
                'participant': store.state.myPid,
                // 'usericon': store.state.myIcon,
                'useralias': store.state.myAlias,
                'position': entry.id,
                'comment': this.newcomment[index]
            }
            try {
                const resp = await apiCalls.postEntry('/poscomments/', newComment)
                if (entry.extracomments == false) {
                    let params = {
                        'extracomments': true
                    }
                    await apiCalls.patchEntry('/positions/' + entry.id + '/', params)
                    entry.extracomments = true //will tell me not to patch the position if I add a second one immediately
                    localStorage.setItem('cnu', '1')
                    console.log('localStorage.getItem(cnu) in enterComment: ', localStorage.getItem('cnu'))
                    // await this.setCommNum('1')
                    // console.log('store.state.commNum: ', store.state.commNum)
                    // console.log('typeof store.state.commNum: ', typeof store.state.commNum)
                } else {

                    let commentsNum = Number(localStorage.getItem('cnu')) + 1
                    localStorage.setItem('cnu', commentsNum)
                    // console.log('localStorage.getItem(cnu) in enterComment 2: ', localStorage.getItem('cnu'))
                    // let commentsNum = Number(store.state.commNum) + 1
                    // await this.setCommNum(commentsNum)
                    // console.log('store.state.commNum: ', store.state.commNum)
                    // console.log('typeof store.state.commNum: ', typeof store.state.commNum)
                }
                if (store.state.particId && store.state.particId != store.state.myPid) { // I am looking at someone else's portfolio, cannot remove or report comments
                    this.updatedcomment[index] = (
                        `${this.updatedcomment[index]}${'\n'}<b><a href="/${resp.data.participant}" style="color: #fff;">${resp.data.useralias}</a></b> - ${resp.data.created.slice(0, 10)}: ${'\n'}${resp.data.comment}${'\n'}`
                    )
                    // console.log('this.updatedcomment[index]3: ',this.updatedcomment[index])
                } else { // I'm in my own portfolio, so can remove this comment
                    // if (entry.commentsNum > 1) {
                    this.updatedcomment[index] = (
                        `${this.updatedcomment[index]}${'\n'}<b><a href="/${resp.data.participant}" style="color: #fff;">${resp.data.useralias}</a></b> - ${resp.data.created.slice(0, 10)}:   ( <i><u><a href="/remove?a=p&c=${resp.data.id}&p=${entry.id}" style="color: #fff;">remove</a></i></u> )${'\n'}${resp.data.comment}${'\n'}`
                    )
                    // console.log('this.updatedcomment[index]4: ',this.updatedcomment[index])
                    // } else if(entry.commentsNum == 1) {

                    // }
                }
                entry.comment = `${'\n'}${this.updatedcomment[index]}`
                entry.comment = entry.comment.trim() // RECENT //Trim drops the first and last line breaks
                document.getElementById('poscomment-' + index).innerHTML = this.updatedcomment[index]
                this.textArea[index] = false
                return this.newcomment[index] = ''
            } catch (error) {
                if (store.state.alertMessage == 1) {
                    return alert(error)
                } else {
                    return console.log('errorTI15: ', error)
                }
            }
        },
        // async getTakenassets() {
        //     try {
        //         let endPoint = '/clubs/' + store.state.myClub + '/'
        //         const Entries = await apiCalls.getEntries(endPoint, {})
        //         this.takenassets = Entries.data['takenassets']
        //         if (this.takenassets) {
        //             console.log('there is a taken asset list')
        //             this.takenassetsObj = JSON.parse(this.takenassets)
        //         }
        //     } catch (error) {
        //         if (store.state.alertMessage == 1) {
        //             return alert(error)
        //         } else {
        //             return console.log('errorTI16: ', error)
        //         }
        //     }
        // },
        toggleSubcard(index) {
            if (this.showComment[index]) {
                this.showComment[index] = false
            }
            if (this.textArea[index]) {
                this.textArea[index] = false
            }
            if (this.showAlert[index]) {
                this.showAlert[index] = false
                this.modifyAbove[index] = false
                this.modifyBelow[index] = false
            }
            if (!this.showCard[index]) {
                this.showCard[index] = !this.showCard[index]
                setTimeout(() => { // NEW 20230308 
                    return document.getElementById('poscard-' + index).scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                }, 0)

            } else {
                return this.showCard[index] = !this.showCard[index]
            }
        },
        async toggleComments(entry, index) {
            console.log('entry in comments: ', entry)
            // if (!this.showComment[index] && entry.extracomments) {
            // console.log('this.showComment[index]: ', this.showComment[index])
            // console.log('this.updatedcomment[index]111: ', this.updatedcomment[index])

            // if (!this.showComment[index] && !this.updatedcomment[index]) {
            if (!this.showComment[index]) {
                if (this.showAlert[index]) {
                    this.showAlert[index] = false
                    this.modifyAbove[index] = false
                    this.modifyBelow[index] = false
                }
                // console.log('HERE1232')
                // this.updatedcomment[index] = ''
                let cutPos = entry.comment.indexOf("INITIAL COMMENT:")
                let aliasstart = entry.comment.substr(0, cutPos).lastIndexOf('\n\n') + 2
                let aliasstr = entry.comment.slice(aliasstart, cutPos - 3)
                let tradesstr = entry.comment.substr(0, cutPos - 3 - aliasstr.length)
                let commentstr = entry.comment.substr(cutPos + 16)
                this.updatedcomment[index] = `${tradesstr}<b><a href="/${entry.participant}" style="color: #fff;">${aliasstr}</a></b> - INITIAL COMMENT:${commentstr}${'\n'}`
                // this.updatedcomment[index] = `${tradesstr}<a href="/${entry.participant}" style="color: #fff;">${aliasstr}</a> - INITIAL COMMENT:${commentstr}${'\n'}`
                // console.log('this.updatedcomment[index]: ', this.updatedcomment[index])
                let params = {
                    'position': entry.id,
                }
                if (entry.extracomments) {
                    try {
                        // await this.setCommNum(0)
                        localStorage.setItem('cnu', '0')
                        const extracomms = await apiCalls.getEntries('/poscomments/', params)
                        if (extracomms && extracomms.data.length > 0) {
                            // entry['commentsNum'] = extracomms.data.length // Needs this info attached to entry (the Position object) to track the number of comments, in case I add/remove any while staying on the page 
                            localStorage.setItem('cnu', extracomms.data.length)
                            console.log('localStorage.getItem(cnu): ', localStorage.getItem('cnu'))
                            // await this.setCommNum(extracomms.data.length)
                            // console.log('store.state.commNum: ', store.state.commNum)
                            // console.log('typeof store.state.commNum: ', typeof store.state.commNum)
                        }
                        console.log('extracomms: ', extracomms)
                        if (store.state.particId && store.state.particId != store.state.myPid) { // I am looking at someone else's portfolio, cannot remove comments
                            // console.log('this.updatedcomment[index]: ', this.updatedcomment[index])
                            extracomms.data.forEach(obj => {
                                this.updatedcomment[index] = (
                                    `${this.updatedcomment[index]}${'\n'}<b><a href="/${obj.participant}" style="color: #fff;">${obj.useralias}</a></b> - ${obj.created.slice(0, 10)}:${'\n'}${obj.comment}${'\n'}`
                                )
                                // console.log('this.updatedcomment[index]2: ', this.updatedcomment[index])
                            })
                            // console.log('this.updatedcomment[index] with extracomments: ', this.updatedcomment[index])
                        } else { // Means it is my own portfolio
                            // if (store.state.commNum > 1) {
                            //     console.log('store.state.commNum: ', store.state.commNum)
                            //     console.log('typeof store.state.commNum: ', typeof store.state.commNum)
                            //     extracomms.data.forEach(obj => {
                            //         if (obj.participant != store.state.myPid) {  // means it is not one of my own comments (has the chance to report)
                            //             this.updatedcomment[index] = (
                            //                 `${this.updatedcomment[index]}${'\n'}<b><a href="/${obj.participant}" style="color: #fff;">${obj.useralias}</a></b> - ${obj.created.slice(0, 10)}:   ( <i><u><a href="/remove/${obj.id}" style="color: #fff;">remove</a></i></u>,   <i><u>report</i></u> )${'\n'}${obj.comment}${'\n'}`
                            //             )
                            //         } else { // it is my own comment, so no need for report
                            //             this.updatedcomment[index] = (
                            //             `${this.updatedcomment[index]}${'\n'}<b><a href="/${obj.participant}" style="color: #fff;">${obj.useralias}</a></b> - ${obj.created.slice(0, 10)}:   ( <i><u><a href="/remove/${obj.id}" style="color: #fff;">remove</a></i></u> )${'\n'}${obj.comment}${'\n'}`
                            //             )
                            //         }
                            //     })
                            // } else if(store.state.commNum == 1) {
                            extracomms.data.forEach(obj => {
                                // if (obj.participant != store.state.myPid) {  // means it is not one of my own comments (has the chance to report)
                                //     this.updatedcomment[index] = (
                                //         `${this.updatedcomment[index]}${'\n'}<b><a href="/${obj.participant}" style="color: #fff;">${obj.useralias}</a></b> - ${obj.created.slice(0, 10)}:   ( <i><u><a href="/remove/${obj.id}/${entry.id}" style="color: #fff;">remove</a></i></u>,   <i><u>report</i></u> )${'\n'}${obj.comment}${'\n'}`
                                //     )
                                // } else { // it is my own comment, so no need for report
                                this.updatedcomment[index] = (
                                    `${this.updatedcomment[index]}${'\n'}<b><a href="/${obj.participant}" style="color: #fff;">${obj.useralias}</a></b> - ${obj.created.slice(0, 10)}:   ( <i><u><a href="/remove?a=p&c=${obj.id}&p=${entry.id}" style="color: #fff;">remove</a></i></u> )${'\n'}${obj.comment}${'\n'}`
                                )
                                // console.log('this.updatedcomment[index] with extracomments2: ', this.updatedcomment[index])
                                // }
                            })
                            // }
                        }
                        // console.log('this.updatedcomment[index] with extracomments 3: ', this.updatedcomment[index])
                        // console.log('this.updatedcomment[index] with extracomments: ', this.updatedcomment[index])
                    } catch (error) {
                        if (store.state.alertMessage == 1) {
                            return alert(error)
                        } else {
                            return console.log('errorTI17: ', error)
                        }
                    }
                }
                // console.log('this.updatedcomment[index] after: ', this.updatedcomment[index])
                document.getElementById('poscomment-' + index).innerHTML = this.updatedcomment[index]
                this.showComment[index] = !this.showComment[index]
                setTimeout(() => { // NEW 20230308 
                    return document.getElementById('possubcard-' + index).scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                }, 0)
            } else {
                return this.showComment[index] = !this.showComment[index]
            }
        },
        toggleSummary() {
            if (this.showSummCard) {
                this.showSummCard = false
            } else if (!this.showSummCard) {
                this.showSummCard = true
                setTimeout(() => { // NEW 20230308 
                    return document.getElementById('summary-card').scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                }, 0)
            }
        },
        async showReduceModal(entry, index) {
            try {
                this.modalReduceEntry = {} // RECENT 0822
                this.warningMsg = ''
                this.errorMsg = ''
                let _action = 'reduce'
                const curData = await this.getCurrentPriceFromApi(entry, _action)
                console.log('curData: ', curData)
                if (!curData) {
                    this.showWarning = true
                }

                this.modalReduceEntry = {
                    ...entry
                }
                this.modalReduceEntry['reduceQty'] = entry.quantity
                this.modalReduceIndex = index
                this.bsReduceModal.show()
            } catch (error) {
                if (store.state.alertMessage == 1) {
                    return alert(error)
                } else {
                    return console.log('errorTI18: ', error)
                }
            }
        },
        hideReduceModal() {
            this.bsReduceModal.hide()
        },
        async showAddModal(entry, index) {
            console.log('entry in showAddModal:', entry)
            try {
                this.modalAddEntry = {} // RECENT 0822
                this.warningMsg = ''
                this.errorMsg = ''
                let _action = 'add'
                const curData = await this.getCurrentPriceFromApi(entry, _action)
                if (!curData) {
                    this.showWarning = true
                }
                this.modalAddEntry = {
                    ...entry
                }
                this.modalAddEntry['addQty'] = ''
                this.modalAddIndex = index
                console.log('entry in showAddModal2:', entry)
                this.bsAddModal.show()
            } catch (error) {
                if (store.state.alertMessage == 1) {
                    return alert(error)
                } else {
                    return console.log('errorTI19: ', error)
                }
            }
        },
        hideAddModal() {
            this.bsAddModal.hide()
        },
        async getAllowComments() {
            try {
                this.commentsVisible = false
                this.commentsAllowed = false
                let particClubsList = []
                let myClubsList = []
                let commonMemberships = false
                // let partID = ''                
                // if (store.state.particId && store.state.particId != store.state.myPid) {
                //     partID = store.state.particId
                //     console.log('partID: ', partID)
                // } else if (store.state.particId && store.state.particId == store.state.myPid) {
                //     return this.commentsAllowed = true
                // } else if (!store.state.particId) {
                //     return this.commentsAllowed = true
                // } else {
                //     return
                // }
                if (this.particId == store.state.myPid) {
                    this.commentsVisible = true
                    this.commentsAllowed = true
                    return
                }
                const blockedMe = await apiCalls.getEntries('/blocks/', {
                    'blocked': store.state.myPid,
                    'blocker': this.particId,
                })
                if (blockedMe && blockedMe.data.length > 0) {
                    this.commentsVisible = false
                    this.commentsAllowed = false
                    return
                }
                if (!this.hideComments && !this.stopComments) {
                    this.commentsVisible = true
                    this.commentsAllowed = true
                    return
                }
                // If at least one of the 2 restrictions is true check if there are commonMemberships
                const particMemberships = await apiCalls.getEntries('/memberships/', {
                    'participant': this.particId,
                    'status': 'active',
                })
                if (particMemberships && particMemberships.data.length > 0) {
                    console.log('particMemberships: ', particMemberships.data)
                    particClubsList = particMemberships.data.map(a => a.club)
                    console.log('particClubsList: ', particClubsList)
                    const myMemberships = await apiCalls.getEntries('/memberships/', {
                        'participant': store.state.myPid,
                        'status': 'active',
                    })
                    if (myMemberships && myMemberships.data.length > 0) {
                        console.log('myMembershipsInPortfolio: ', myMemberships.data)
                        myClubsList = myMemberships.data.map(a => a.club)
                        console.log('myClubsList: ', myClubsList)
                        commonMemberships = particClubsList.some(item => myClubsList.includes(item))
                    } else {
                        commonMemberships = false
                    }
                } else {
                    commonMemberships = false
                }
                if (commonMemberships == true) {
                    this.commentsVisible = true
                    this.commentsAllowed = true
                    return
                }
                // if there are no common memberships, then the restrictions apply
                if (this.hideComments) {
                    this.commentsVisible = false
                    this.commentsAllowed = false
                    return
                } else if (this.stopComments) {
                    this.commentsVisible = true
                    this.commentsAllowed = false
                    return
                }
            } catch (err) {
                console.log('error in getAllowComments: ', err)
                this.commentsVisible = false
                this.commentsAllowed = false
                return
            }
        },
        toggleAlert(entry, index) {

            if (this.showAlert[index]) {
                this.showAlert[index] = false
                this.modifyAbove[index] = false
                this.modifyBelow[index] = false

            } else if (!this.showAlert[index]) {
                if (Number(entry.alertabove) > 0) {
                    console.log('entry.alertabove: ', entry.alertabove)
                    this.alertAbove[index] = Number(entry.alertabove)
                    this.alertAbove[index] = Number(this.alertAbove[index].toFixed(4))
                    console.log('this.alertAbove[index]: ', this.alertAbove[index])
                    console.log('this.modifyAbove[index]: ', this.modifyAbove[index])
                    console.log('this.modifyBelow[index]: ', this.modifyBelow[index])

                } else {
                    this.alertAbove[index] = ''
                }
                if (Number(entry.alertbelow) > 0) {
                    console.log('entry.alertbelow: ', entry.alertbelow)
                    this.alertBelow[index] = Number(entry.alertbelow)
                    this.alertBelow[index] = Number(this.alertBelow[index].toFixed(4))
                    console.log('this.alertAbove[index]: ', this.alertAbove[index])
                } else {
                    this.alertBelow[index] = ''
                }
                // if (this.showAlert[index]) {
                //     this.showAlert[index] = false
                // } else if (!this.showAlert[index]) {
                this.showComment[index] = false
                this.showAlert[index] = true
                setTimeout(() => { // NEW 20230308 
                    return document.getElementById('posalert-' + index).scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                    })
                }, 0)
                // }
            }
        },
        validAlert(input) {
            console.log('input: ', input)
            if (typeof input == 'number' && input > 0 && input < 1000000) {
                if (Math.round(input * 10000) / 10000 != input) {
                    input = Math.round(input * 10000) / 10000
                }
                return true
            } else {
                console.log('Input not valid')
                console.log('typeof input: ', typeof input)
                return false
            }
        },
        modifyAlert(index, typ) {
            if (typ == 'above') {
                this.modifyAbove[index] = true
                this.alertAbove[index] = ''
                setTimeout(() => { // NEW 20230308
                    document.getElementById('posabovebox-' + index).focus()
                }, 0)
            } else if (typ == 'below') {
                console.log('index2: ', index)
                this.modifyBelow[index] = true
                this.alertBelow[index] = ''
                setTimeout(() => { // NEW 20230308
                    document.getElementById('posbelowbox-' + index).focus()
                }, 0)
            } else {
                return
            }
        },
        async enterAlert(entry, index, typ, enter) {
            try {
                console.log('entry: ', entry)
                if (typ == 'above') {
                    if (this.alertAbove[index] <= 0 || this.alertAbove[index] == '') {
                        return this.cancelAlert(entry, index, typ)
                    }
                    // if (this.alertAbove[index] == '') {
                    //     if (Number(entry.alertabove) > 0) {
                    //         this.alertAbove[index] = Number(entry.alertabove)
                    //         this.alertAbove[index] = Number(this.alertAbove[index].toFixed(4))
                    //         if (this.alertAbove[index] == 0) {
                    //             this.alertAbove[index] = ''
                    //         }
                    //     }
                    //     return
                    // }
                    if (enter == false) {
                        this.alertAbove[index] = 0
                    }
                    // this.alertBelow[index] = Number(this.alertBelow[index])
                    console.log('this.alertBelow[index]: ', typeof this.alertAbove[index])
                    this.alertAbove[index] = Number(this.alertAbove[index])
                    this.alertAbove[index] = Number(this.alertAbove[index].toFixed(4))
                    console.log('this.alertAbove[index] 2: ', typeof this.alertAbove[index])
                    if (this.alertAbove[index] == Number(entry.alertabove)) {
                        console.log('Was the same')
                        this.modifyAbove[index] = false
                    }
                    if (enter == false || this.validAlert(this.alertAbove[index])) {
                        console.log('Patching: ', this.alertAbove[index])
                        const patchedpos = await apiCalls.patchEntry('/positions/' + entry.id + '/', {
                            'alertabove': this.alertAbove[index]
                        })
                        if (patchedpos) {
                            console.log('patchedpos: ', patchedpos.data)
                            entry.alertabove = this.alertAbove[index]
                            if (entry.alertabove == 0) {
                                this.alertAbove[index] = ''
                            }
                            this.modifyAbove[index] = false
                            setTimeout(() => {
                                return document.getElementById('posalert-' + index).scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center'
                                })
                            }, 0)
                        } else {
                            console.log('position not patched')
                        }
                    } else {
                        console.log('Input not entered')
                        console.log("this.alertBelow[index] != '': ", this.alertAbove[index] != '')
                        console.log("this.alertBelow[index] != Number(entry.alertabove): ", this.alertAbove[index] != Number(entry.alertabove))
                        return
                    }
                } else if (typ == 'below') {
                    if (this.alertBelow[index] <= 0 || this.alertBelow[index] == '') {
                        return this.cancelAlert(entry, index, typ)
                    }
                    // if (this.alertBelow[index] == '') {
                    //     if (Number(entry.alertbelow) > 0) {
                    //         this.alertBelow[index] = Number(entry.alertbelow)
                    //         this.alertBelow[index] = Number(this.alertBelow[index].toFixed(4))
                    //         if (this.alertBelow[index] == 0) {
                    //             this.alertBelow[index] = ''
                    //         }
                    //     }
                    //     return
                    // }
                    if (enter == false) {
                        this.alertBelow[index] = 0
                    }
                    // this.alertBelow[index] = Number(this.alertBelow[index])
                    console.log('this.alertBelow[index]: ', typeof this.alertBelow[index])
                    this.alertBelow[index] = Number(this.alertBelow[index])
                    this.alertBelow[index] = Number(this.alertBelow[index].toFixed(4))
                    console.log('this.alertBelow[index] 2: ', typeof this.alertBelow[index])
                    // if (Math.round(this.alertBelow[index] * 10000)/10000 != this.alertBelow[index]) {
                    //     // this.alertBelow[index] = Math.round(this.alertBelow[index] * 10000)/10000
                    //     Number(this.alertBelow[index].toFixed(4))
                    // }
                    if (this.alertBelow[index] == Number(entry.alertbelow)) {
                        console.log('Was the same')
                        this.modifyBelow[index] = false
                    }
                    if (enter == false || this.validAlert(this.alertBelow[index])) {
                        const patchedpos = await apiCalls.patchEntry('/positions/' + entry.id + '/', {
                            'alertbelow': this.alertBelow[index]
                        })
                        if (patchedpos) {
                            console.log('patchedpos: ', patchedpos.data)
                            entry.alertbelow = this.alertBelow[index]
                            if (entry.alertbelow == 0) {
                                this.alertBelow[index] = ''
                            }
                            this.modifyBelow[index] = false
                            setTimeout(() => {
                                return document.getElementById('posalert-' + index).scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center'
                                })
                            }, 0)
                        } else {
                            console.log('position not patched')
                        }
                    } else {
                        console.log('Input not entered')
                        console.log("this.alertBelow[index] != '': ", this.alertBelow[index] != '')
                        console.log("this.alertBelow[index] != Number(entry.alertbelow): ", this.alertBelow[index] != Number(entry.alertbelow))
                        return
                    }
                } else {
                    return
                }
            } catch (err) {
                console.log(err)
            }
        },
        cancelAlert(entry, index, typ) {
            if (typ == 'above') {
                if (entry.alertabove <= 0) {
                    this.alertAbove[index] = ''
                    return this.modifyAbove[index] = false
                }
                this.alertAbove[index] = Number(entry.alertabove)
                this.alertAbove[index] = Number(this.alertAbove[index].toFixed(4))
                return this.modifyAbove[index] = false
            } else if (typ == 'below') {
                if (entry.alertbelow <= 0) {
                    this.alertBelow[index] = ''
                    return this.modifyBelow[index] = false
                }
                this.alertBelow[index] = Number(entry.alertbelow)
                this.alertBelow[index] = Number(this.alertBelow[index].toFixed(4))
                return this.modifyBelow[index] = false
            } else {
                return
            }
        },
        onResize() {
            this.width = window.innerWidth
        },
        // validAddQty() {
        //     if (!this.modalAddEntry['addQty'] || this.modalAddEntry['addQty'] <= 0 || Math.round(this.modalAddEntry['addQty']) != this.modalAddEntry['addQty']) {
        //         // this.warningMsg = 'Please enter an integer number in Quantity field.'
        //         this.modalAddEntry['addQty'] = ''
        //         return false
        //     }
        //     return true
        // },
        // validReduceQty() {
        //     if (!this.modalReduceEntry['reduceQty'] || this.modalReduceEntry['reduceQty'] <= 0 || Math.round(this.modalReduceEntry['reduceQty']) != this.modalReduceEntry['reduceQty']) {
        //         // this.warningMsg = 'Please enter an integer number in Quantity field.'
        //         this.modalReduceEntry['reduceQty'] = ''
        //         return false
        //     }ubs.ch
        //     return true
        // },
    },
    async created() {
        try {
            console.log('creating portfolio 1')
            this.baseCurrency = store.state.myCur // temporarily here to avoid error in rendering until it gets it through getParticipant
            await this.getParticipant()
            if (store.state.page != '/investor' || (store.state.commentsVisible == null || store.state.commentsAllowed == null)) {
                await this.getAllowComments()
                console.log('AAAATTTT comments settings not used in portfolio created: ', store.state.page)
            } else {
                this.commentsVisible = store.state.commentsVisible
                this.commentsAllowed = store.state.commentsAllowed
                console.log('AAAATTTT used store.state.commentsVisible and store.state.commentsAllowed in portfolio created: ', store.state.page)
            }
            await this.getAssets()
            // console.log('creating portfolio 2')
            // console.log('this.modifyAbove[0]: ', this.modifyAbove[0])
            // console.log('this.alertAbove[0]: ', this.alertAbove[0])
            // console.log('this.modifyBelow[0]: ', this.modifyBelow[0])
            // console.log('this.alertBelow[0]: ', this.alertBelow[0])
        } catch (error) {
            if (store.state.alertMessage == 1) {
                return alert(error)
            } else {
                return console.log('errorTI22: ', error)
            }
        }
    },
    mounted() {
        window.addEventListener('resize', this.onResize)
        this.width = window.innerWidth
        this.myReduceModal = document.getElementById('reduceModal')
        this.bsReduceModal = new Modal(this.myReduceModal)
        this.myAddModal = document.getElementById('addModal')
        this.bsAddModal = new Modal(this.myAddModal)
    },
    unmounted() {
        window.removeEventListener('resize', this.onResize)
    }
}
</script>

<style scoped>
.title-row {
    padding-top: 10px;
    margin-bottom: -50px;
}

.entry-card {
    background-color: #413e3c !important;
    height: 60px;
    width: 100%;
    margin: 3px auto;
    padding-top: 5px;
    text-align: center;
    font-size: 0.8rem;
}

.empty-card {
    background-color: #413e3c;
    height: 40px;
    margin-top: 70px;
    padding-top: 6px;
    text-align: center;
}

.spacer-row {
    height: 15px;
}

.card-headers {
    height: 30px;
    background-color: #272522 !important;
    /* color: #d97b2d !important;
    color: #f48043 !important; */
    margin-bottom: -5px;
    margin-top: 55px;
    /* recent was 55 */
    text-align: center;
    border: none;
    font-size: 0.8rem;
}

.cell-headers {
    color: #6c6c6c !important;
    font-size: 1rem;
    /* text-decoration: underline; */
    /* margin-left: -3px; */
    padding-left: 0px;
    padding-right: 0px;
}

.head-1 {
    margin-left: 9px;
    margin-right: -9px;
}

.head-2 {
    /* text-align: end; */
    /* margin-left: -3px; */
    /* margin-right: -7x; */
    padding-left: 0px;
    padding-right: 0px;
}

.head-3 {
    margin-left: -5px;
    /* margin-right: 0px; */
}

.head-4 {
    margin-right: -8px;
    /* margin-right: 0px; */
}

.cell-tick {
    text-align: center;
    color: #dbd7d4 !important;
    text-decoration: underline;
    font-size: 1rem;
    /* margin-left: -3px; */
    padding-left: 10px;
    padding-right: 0px;
    margin-left: 2px;
    margin-right: -3px;
}

/* .cell-left {
    padding-left: 10px;
    padding-right: 0px;
    margin-left: 2px;
    margin-right: -3px;
} */
.cell-pri {
    /* right text*/
    /* color: #babab9 !important; */
    color: #dbd7d4 !important;
}

.cell-neg {
    /* color: #b33430; */
    /* color: #ff8a65; */
    /* color: #fc7f59; */
    color: #e25651;
}

.cell-neg2 {
    /* color: #ffab91;
    color: #ff6e40;
    color: #a50c07;
    color: #8f0f0a;
    color: #3d0402;
    color: #C1383E; */
    color: #3d0402;
    filter: brightness(1.3);
}

.cell-pos {
    /* color: #7fa650; */
    color: #64dd17;
}

.cell-qty {
    font-size: 0.9rem;
    text-align: center;
    position: relative;
    left: 8px !important;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: -1px;
    margin-right: 3px;
}

.cell-curr {
    /* right text*/
    /* color: #c19556 !important; */
    color: #babab9 !important;
    margin-left: -3px;
}

.cell-mid1 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: -5px;
}

.cell-mid2 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.cell-mid3 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.cell-right {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -5px;
    margin-right: 0px;
}

.entry-subcard {
    /* left-right sides of card */
    /* background-color: #6a6867 !important; */
    /* background-color: #7f7D7A !important; */
    background-color: #62605E !important;
    height: 140px;
    width: 100%;
    margin: 3px auto;
    padding-top: 10px;
    text-align: center;
    font-size: 0.8rem;
    color: #000 !important;
    padding-top: 5px;
    /* for the text inside the card */
    /* border-radius: 5px; */
    /* color: #babab9 !important; */
}

.cell-subc {
    color: #fff;
    font-size: 0.8rem;
    text-align: center;
}

.cell-subc-left {
    padding-left: 3px;
    padding-right: 0px;
    margin-left: 5px;
    margin-right: -5px;
    margin-top: -2px;
    overflow-wrap: normal;
    /* padding-top: 0px !important; */
    /* padding-bottom: 8px !important; */
}

.cell-subc-mid1 {
    padding-left: 0px;
    padding-right: 0px;
    /* margin-left: 3px; */
    margin-left: 0px;
    margin-right: -5px;
    z-index: 1;
}

.cell-subc-mid2 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.cell-subc-mid3 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
    /* margin-left: -3px;
    margin-right: 3px; */
}

.cell-subc-right {
    padding-left: 0px;
    padding-right: 0px;
    /* margin-left: -10px; */
    margin-left: -5px;
    margin-right: 0px;
}

.cell-subc-low1 {
    margin-top: 15px;
    margin-left: 0px;
    margin-right: -2px;
}

.cell-subc-low2 {
    margin-top: 15px;
    margin-left: -8px;
    margin-right: 2px;

}

.cell-subc-low3 {
    margin-top: 15px;
    margin-left: -2px;
}

.subc-head {
    /* text-decoration: underline; */
    text-decoration: none;
    margin-bottom: 4px;
}

.cell-titl {
    font-size: 0.9rem;
    text-align: start;
    position: relative;
    left: 8px !important;
}

/* .last-row-subc-pos {
    margin-top: 15px;
} */

.comments-cell {
    /* text-decoration: underline; */
    font-size: 1rem;
    text-align: start;
    position: relative;
    left: 4px !important;
}

.cell-neu {
    color: #dbd7d4;
}

.cell-disabled {
    padding-right: 0px;
    text-decoration: none;
    color: #b2b2b9 !important;
    overflow-wrap: normal;
}

.cell-light {
    text-decoration: underline;
    color: #e2e2e2;
}

.closepos-cell {
    font-size: 1rem;
    text-align: center;
    text-decoration: underline;
    color: #dbd7d4 !important;
    /* position: relative; */
    /* margin-right: 10px; */
}

.close-btn {
    margin-top: -10px;
    margin-left: -5px;
}

.close-butt {
    height: 25px !important;
    width: 10px !important;
    text-align: start !important;
    background-color: #b33430;
    color: white;
    margin-top: 10px !important;
}

.btn-tex {
    position: relative;
    top: -8px;
    left: -4px;
}

/* ***** MODAL ****************************************************** */
.modal-area {
    /* background-color: #dbd7d4; */
    background-color: #e4e4e4;
}

.warn-card {
    background-color: #e4e4e4;
    border: none;
    padding-left: 10px;
}

/* .close-modal-butt {
    height: 10px !important;
    width: 10px !important;
    text-align: start !important;
    background-color: #b33430 !important;
    color: white;
    margin-top: 10px !important;
} */

.modal-title2 {
    color: #4d4d4d;
}

.modal-body2 {
    color: #4d4d4d;
}

.reduce-qty {
    background-color: #fff;
    width: 80px;
    margin-top: 8px;
    border: none;
}

.mid-row-modal {
    margin-top: 20px;
}

.top-row-modal {
    margin-top: -20px;
}

.modal-input {
    margin-right: 11px !important;
    margin-left: -10px !important;
    margin-top: -5px !important;
    font-weight: bold;
}

.last-row-modal1 {
    text-align: start;
    padding: 0 0;
    font-weight: bold;
    margin-top: 3px;
}

.modal-row {
    text-align: center;
}

.modal-footer {
    display: flex;
    justify-content: center;
}

.modal-btn {
    /* width: 80px; */
    padding-left: 20px;
    padding-right: 20px;
    height: 38px;
    margin-right: 10px !important;
    margin-left: -13px !important;
    margin-top: -3px !important;
    background-color: #2F5C85 !important;
    color: white !important;
    border: none !important;

}

.col-bold {
    font-weight: bold;
}

/* .btn-secondary {
    margin-left: 10px !important;
} */

.add-mod-warn {
    color: rgb(240, 76, 76);
    font-weight: bold;
    padding-right: 0px;
    padding-left: 0px;
}

/* ********* COMMENTS ************* */
/* .comments-card {
    margin: 5px 0;
    color: #000;
    background-color: #dbd7d4;
} */
.comments-card {
    margin: 3px 0;
    /* color: #dbd7d4; */
    color: #fff;
    /* background-color: #413e3c; */
    /* background-color: #62605E;
    background-color: #7f7D7A;
    background-color: #797774; */
    background-color: #7C7B78;
    /* background-color: #6f6D6A; */
    /* border: 1px; */
    padding: 5px 5px;
    font-size: 0.9rem;
}

.line-break-and-tab {
    white-space: pre-wrap;
}

/* #just-line-break {
    white-space: pre-line;
} */
.comment-textarea {
    height: 150px !important;
    /* background-color: #d1d1d1; */
    background-color: #dbd7d4;
    color: #000;
    margin-top: 0px;
    margin-bottom: 5px;
    padding-top: 10px !important;
    font-size: 1rem;
}

.addcomm-btn {
    background-color: #2F5C85 !important;
    /* background-color: #6a6867 !important; */
    color: #fff !important;
    margin-bottom: 10px;
}

.entercomm-btn {
    background-color: #2F5C85 !important;
    color: #fff !important;
    margin-bottom: 10px;
    margin-right: 20px;
}

.btn-secondary-comment {
    height: 38px;
    margin-bottom: 10px;
    margin-left: 0px;
}

/********** END COMMENTS ***********/

/********** OLD SUMMARY WITH VERTICAL SPLIT ******Additional for Pos ***********/
/* .summ-card {
    text-align: center;
    width: 100%;
    margin-top: -1px !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    overflow-wrap: normal;
    height: 80px;
    background-color: #272522;
}
.row-summ {
    margin-left: 0px;
}
.cell-summ-empty {
    margin-left: 5px;
    margin-right: -13px;
    padding-right: 0px;
}
.bottom-row-summ {
    height: 100%;
    background-color: #272522;
}
.cell-pl {
    font-size: 0.9rem !important;
}
.cell-border-tr {
    border-top-right-radius: 4px;
}
.cell-border-br {
    border-bottom-right-radius: 4px;
}
.cell-border-tl {
    border-top-left-radius: 4px;
}
.cell-border-bl {
    border-bottom-left-radius: 4px;
}
.cell-titles {
    text-align: center;
    color: #babab9 !important;
    font-size: 1rem;
    background-color: #413e3c;
}
.cell-pri-summ {
    color: #dbd7d4 !important;
    background-color: #413e3c;
    font-size: 0.8rem;
}
.cell-low1 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -1px;
    margin-right: -3px;
    z-index: 1;
}
.cell-low11 {
    color: #dbd7d4 !important;
}
.cell-low2 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 1px;
    margin-right: 2px;
}
.cell-low3 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -2px;
    margin-right: 2px;
    border-right: none;
}
.cell-low4 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -2px;
    margin-right: -3px;
    border-left: none;
}
.cell-curr-summ {
    color: #babab9 !important;
    font-size: 0.8rem;
    padding-top: 2px;
} */
/* ********* END OLDSUMMARY ********** */

.btn-alert-set {
    background-color: #2F5C85 !important;
    /* margin-right: 20px; */
    width: 100%;
    padding-top: 0px;
    padding-bottom: 4px;
    padding-left: 5px;
    padding-right: 5px;
    height: 25px;
    /* font-weight: bold; */
    color: #fff !important;
    margin-left: 0px;
    margin-right: 0px;
}

.btn-alert-modify {
    background-color: #2F5C85 !important;
    width: 70px;
    padding-top: 0px;
    padding-bottom: 4px;
    padding-left: 0px;
    padding-right: 0px;
    height: 25px;
    /* font-weight: bold; */
    color: #fff !important;
    margin-left: 5px;
    margin-right: 0px;
}

.btn-alert-enter {
    background-color: #2F5C85 !important;
    /* margin-right: 20px; */
    width: 70px;
    padding-top: 0px;
    padding-bottom: 4px;
    padding-left: 10px;
    padding-right: 10px;
    height: 25px;
    /* font-weight: bold; */
    color: #fff !important;
    margin-left: 5px;
    margin-right: 0px;
}

.btn-alert-cancel {
    background-color: #525259 !important;
    width: 65px;
    margin-top: -1px;
    padding-top: 0px !important;
    padding-bottom: 5px !important;
    padding-left: 0px;
    padding-right: 0px;
    height: 28px;
    /* font-weight: bold; */
    color: #fff !important;
    margin-left: 50px;
    margin-right: 0px;
}

.btn-alert-remove {
    background-color: rgba(214, 55, 55, 0.897) !important;
    width: 65px;
    margin-top: -1px;
    padding-top: 0px !important;
    padding-bottom: 5px !important;
    padding-left: 0px;
    padding-right: 0px;
    height: 28px;
    /* font-weight: bold; */
    color: #fff !important;
    margin-left: 50px;
    margin-right: 0px;
}

/* ******************* BTN 3D ******************* */
.btn3d {
    border: 0;
    transition: all 40ms linear;
}

.btn3d.btn-primar {
    box-shadow: 0 0 0 1px #2f5b85a1 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

.btn3d.btn-primar:hover {
    box-shadow: 0 0 0 1px #4a95db34 inset, 0 0 0 1px rgba(255, 255, 255, 0.15) inset, 0 3px 0 0 #2f5b85a1;
    background-color: #2F5C85;
}

/* ******************* END BTN 3D ******************* */

.total-card {
    background-color: #413e3c !important;
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    padding-top: 14px;
    padding-bottom: 8px;
    /* margin: 3px auto;     */
    margin-top: 2px;
}

.sep-line {
    background-color: #62605E !important;
    /* background-color: #6c6c6c !important; */
    padding-top: 1px !important;
    margin-top: 1px;
    margin-bottom: 1px;
    width: 94%;
    margin-left: 2%;
    box-shadow: 0px 1px #272522;
}

.sep-line2 {
    background-color: #b3b5b9 !important;
    /* background-color: #6c6c6c !important; */
    padding-top: 1px !important;
    margin-top: 2px;
    margin-bottom: -3px;
    width: 94%;
    margin-left: 2%;
    box-shadow: 0px 1px #272522;
}

.total-row {
    padding-top: 1px;
}

.summ-font {
    font-size: 0.9rem;
    color: #dbd7d4 !important;
    padding-left: 0px;
    padding-right: 0px;
    overflow-wrap: normal;
}

.summ-font2 {
    text-align: center;
    font-size: 0.9rem;
    color: #dbd7d4 !important;
    padding-left: 0px;
    margin-left: 0px;
    padding-right: 0px;
    overflow-wrap: normal;
}

.summ-num {
    font-size: 0.8rem;
    color: #dbd7d4 !important;
    padding-left: 0px;
    padding-right: 0px;
    overflow-wrap: normal;
}

.summ-toprow {
    margin-bottom: 6px;
}

.summ-title {
    font-size: 1rem;
    text-decoration: underline;
    color: #dbd7d4 !important;
    padding-left: 0px;
    padding-right: 0px;
    overflow-wrap: normal;
    padding-left: 11px;
    padding-right: 0px;
    margin-left: 10px;
    margin-right: -20px;
}

.summ-pos11 {
    padding-left: 11px;
    padding-right: 0px;
    margin-left: 10px;
    margin-right: -20px;
}

.cell-pos12 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 6px;
    margin-right: -10px;
}

.summ-pos13 {
    margin-left: 9px;
}

.summ-pos14 {
    margin-left: -2px;
    margin-right: 1px;
}

.summ-pos15 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -4px;
    margin-right: 0px;
}

.summ-pos21 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 14px;
    margin-right: 0px;
}

.summ-pos22 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: -4px;
}

.summ-pos26 {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -16px;
    margin-right: 0px;
}

.summ-subcard {
    background-color: #62605E !important;
    width: 100%;
    margin: 3px auto;
    text-align: center;
    font-size: 0.8rem;
    padding: 11px 5px 8px;

}

.alert-row-1 {
    margin-top: 5px;
}

.alert-row-1-open {
    margin-top: 5px;
    margin-bottom: -2px;
}

.alert-row-2 {
    margin-top: 30px;
    margin-bottom: 10px;
}

.alert-row-2-open {
    margin-top: 30px;
    margin-bottom: 8px;
}

.col-prialert {
    background-color: #dad2d2;
    color: #000;
    text-align: center;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -5px;
    margin-right: 8px;
    padding-top: 2px;
    padding-bottom: 0px;
}

.col-alertbox {
    background-color: #fff;
    padding-bottom: 4px !important;
    margin-bottom: 0px !important;
}

.col-alert-enter {
    color: #fff;
    padding-left: 0px;
    padding-right: 0px;
    margin-left: -18px;
    margin-right: -18px;
}

.col-alert-cancel {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    /* margin-right: -20px; */
}

.col-alert-set {
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
}

.alert-box {
    height: 24px;
    width: 100% !important;
    text-align: center;
    padding-left: 0px !important;
    padding-right: 0px !important;
    background-color: #fff !important;
    color: #000 !important;
    font-weight: bold !important;
    font-size: 0.9rem !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    border: none !important;
}

.alert-box:focus {
    background-color: #fff;
    outline: none;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}
</style>
